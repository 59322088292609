import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../app.config'
import { encoded } from '../../../../utility/Utils'

export const fetchDepartmentMasterList = createAsyncThunk(
  'department/fetchDepartmentMasterList',
  async (params, { dispatch }) => {
    try {
      const url = params?.pageNo
        ? `/gpl-department?pageNo=${params.pageNo}&pageSize=${
            params.pageSize
          }&search=${encoded(params.search)}`
        : `/gpl-department?functionId=${params.functionId}`

      const response = await axios.get(baseUrl() + url)
      return {
        departmentMasterList: response?.data?.data,
        totalPages: response?.data?.count?.total
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

export const fetchDepartmentMasterDropdown = createAsyncThunk(
  'department/fetchDepartmentMasterDropdown',
  async (params, { dispatch }) => {
    try {
      const response = await axios.get(baseUrl() + '/gpl-department')
      console.log('departmentMasterDropdown', response)
      return {
        departmentMasterDropdown: response?.data?.data
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

export const addDepartmentMaster = createAsyncThunk(
  'department/addDepartmentMaster',
  async ({ name, gplFunctionId, params }, { dispatch }) => {
    try {
      await axios.post(baseUrl() + '/gpl-department', {
        name,
        gplFunctionId
      })
      toast.success('Department added successfully')
      dispatch(fetchDepartmentMasterList({ ...params }))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const editDepartmentMaster = createAsyncThunk(
  'department/editDepartmentMaster',
  async ({ id, name, gplFunctionId, params }, { dispatch }) => {
    try {
      await axios.put(baseUrl() + `/gpl-department/${id}`, {
        name,
        gplFunctionId
      })
      toast.success('Department edited successfully')
      dispatch(fetchDepartmentMasterList({ ...params }))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const deleteDepartmentMaster = createAsyncThunk(
  'department/deleteDepartmentMaster',
  async ({ ids, pageNo, pageSize, search }, { dispatch }) => {
    dispatch(setLoader(true))
    try {
      await axios.delete(baseUrl() + `/gpl-department`, {
        data: { ids }
      })
      toast.success('Department deleted successfully')
      dispatch(
        fetchDepartmentMasterList({ pageNo: 1, pageSize, search: search })
      )
      dispatch(setPageNo(1))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const department = createSlice({
  name: 'department',
  initialState: {
    departmentMasterList: [],
    departmentMasterDropdown: [],
    loader: true,
    params: {
      pageNo: 1,
      pageSize: 10,
      search: ''
    },
    totalPages: 0,
    selected: null
  },
  reducers: {
    selectDepartmentMaster: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setPageNo: (state, action) => {
      state.params = { ...state.params, pageNo: action.payload }
    },
    setPageSize: (state, action) => {
      state.params = { ...state.params, pageSize: action.payload }
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDepartmentMasterList.fulfilled, (state, action) => {
      state.departmentMasterList = action?.payload?.departmentMasterList
      state.totalPages = action?.payload?.totalPages
      state.loader = false
    }),
      builder.addCase(
        fetchDepartmentMasterDropdown.fulfilled,
        (state, action) => {
          state.departmentMasterDropdown =
            action?.payload?.departmentMasterDropdown
        }
      )
  }
})

export const {
  selectDepartmentMaster,
  setLoader,
  setPageNo,
  setPageSize,
  setSearch
} = department.actions

export default department.reducer
