import React from 'react'
import { Filter } from 'react-feather'
import { Row, Col, Label, Input, Button } from 'reactstrap'
import FilterForm from '../../views/attendance/Filter'
import { useDispatch } from 'react-redux'
import '../../../src/common.css'
import FilterFormEnroll from '../../views/enroll/Filter'
import CareerFilterForm from '../../views/careerAspiration/FilterForm'

export const CustomHeader = ({
  selectedFilters,
  filters,
  getData,
  params,
  search,
  handleSearch,
  addClick,
  exportClick,
  importClick,
  addName,
  importName,
  exportName,
  showSearch,
  showAddButton = true,
  exportBtn = false,
  importBtn = false,
  title,
  permissions,
  filter,
  setFilter,
  filterType,
  clearFilter,
  setClearFilter,
  showFilter = false,
  loader,
  handleFilterClosed,
  setSelected
}) => {
  const dispatch = useDispatch()
  return (
    <>
      <Row className="text-nowrap w-100 my-75 g-0 permission-header">
        <Col xs={12} lg={4} className={`d-flex align-items-center mt-lg-0`}>
          <h3 className="text-height ">{title}</h3>
        </Col>
        <Col xs={12} lg={8}>
          <div className="d-flex align-items-center justify-content-lg-end justify-content-start flex-md-nowrap flex-wrap mt-lg-0 mt-1">
            <div
              className="d-flex align-items-center"
              style={{ margin: '0px 10px' }}
            >
              {showSearch && (
                <>
                  <label
                    className="mb-0"
                    id="table-search"
                    htmlFor="search-permission"
                  >
                    Search:
                  </label>
                  <Input
                    type="text"
                    value={search}
                    id="search-permission"
                    className="ms-50 w-100"
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                </>
              )}
            </div>
            {showFilter && (
              <div
                className="d-flex align-items-center newDiv"
                style={{
                  margin: '0px 10px',
                  padding: '5px 10px',
                  borderRadius: '5px'
                }}
              >
                <Filter
                  className=" font-medium-2"
                  onClick={() => {
                    setFilter(true)
                  }}
                  color="gray"
                ></Filter>
                {clearFilter && (
                  <Label
                    className="fw-bolder labelForm"
                    onClick={() => {
                      setClearFilter(false)
                      dispatch(setSelected(null))
                      dispatch(selectedFilters(null))
                      dispatch(
                        getData({ params: { ...params, pageNo: 1 }, filters })
                      )
                    }}
                    style={{
                      cursor: 'pointer',
                      verticalAlign: 'middle',
                      marginBottom: '0 !important',
                      paddingLeft: '20px',
                      paddingTop: '5px'
                    }}
                  >
                    clear
                  </Label>
                )}
                {filter && filterType == 'enroll' && (
                  <FilterFormEnroll
                    setFilter={setFilter}
                    filter={filter}
                    handleFilterClosed={handleFilterClosed}
                    clearFilter={clearFilter}
                    setClearFilter={setClearFilter}
                    params={params}
                    filters={filters}
                    setSelected={setSelected}
                  />
                )}
                {filter && filterType == 'attendance' && (
                  <FilterForm
                    setFilter={setFilter}
                    filter={filter}
                    handleFilterClosed={handleFilterClosed}
                    clearFilter={clearFilter}
                    setClearFilter={setClearFilter}
                    params={params}
                    filters={filters}
                    setSelected={setSelected}
                  />
                )}
                {filter && filterType == 'careerAspiration' && (
                  <CareerFilterForm
                    setFilter={setFilter}
                    filter={filter}
                    handleFilterClosed={handleFilterClosed}
                    clearFilter={clearFilter}
                    setClearFilter={setClearFilter}
                    params={params}
                    filters={filters}
                    setSelected={setSelected}
                  />
                )}
              </div>
            )}

            {permissions && showAddButton && (
              <Button
                className="add-permission mb-sm-0"
                color="primary"
                disabled={loader}
                onClick={() => addClick('add')}
              >
                {addName}
              </Button>
            )}
            {exportBtn && (
              <Button
                className="add-permission mx-1 mb-sm-0"
                color="primary"
                onClick={() => exportClick()}
              >
                {exportName}
              </Button>
            )}
            {importBtn && (
              <Button
                className="add-permission mx-1 mb-sm-0"
                color="primary"
                onClick={() => importClick()}
              >
                {importName}
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </>
  )
}

export default CustomHeader
