// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl } from '../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const fetchHomeList = createAsyncThunk(
  'home/fetchHomeList',
  async (params) => {
    try {
      const response = await axios.get(baseUrl() + '/config/footer-document', {
        params
      })
      return {
        homeList: response?.data?.data,
        totalPages: response?.data?.count?.total
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

export const addHomePdf = createAsyncThunk(
  'home/addHomePdf',
  async ({ formData, params }, { dispatch }) => {
    try {
      await axios.put(baseUrl() + '/config/footer-document', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      toast.success('Home file added successfully')
      dispatch(fetchHomeList({ ...params }))
      dispatch(setLoader(false))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const addFooterText = createAsyncThunk(
  'home/addFooterText',
  async ({ params }, { dispatch }) => {
    try {
      await axios.put(baseUrl() + `/config/footer-Text`, {
        ...params
      })
      toast.success('Home Text added successfully')
      dispatch(fetchHomeList({ ...params }))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const updateHomeStatus = createAsyncThunk(
  'home/updateHomeStatus',
  async ({ payload, params }, { dispatch }) => {
    try {
      await axios.patch(
        baseUrl() +
          `/config/footer-document?flag=${payload.flag}&key=${payload.key}`
      )
      toast.success('Home status updated successfully')
      await dispatch(fetchHomeList({ ...params }))
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const home = createSlice({
  name: 'home',
  initialState: {
    homeList: [],
    loader: true,
    params: {
      pageNo: 1,
      pageSize: 10,
      search: ''
    },
    totalPages: 0,
    selected: null
  },
  reducers: {
    selectHome: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setPageNo: (state, action) => {
      state.params = { ...state.params, pageNo: action.payload }
    },
    setPageSize: (state, action) => {
      state.params = { ...state.params, pageSize: action.payload }
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchHomeList.fulfilled, (state, action) => {
      state.homeList = action?.payload?.homeList
      state.totalPages = action?.payload?.totalPages
      state.loader = false
    })
  }
})

export const { selectHome, setLoader, setPageNo, setPageSize, setSearch } =
  home.actions

export default home.reducer
