import { selectThemeColors } from '@utils'
import Select from 'react-select'
import React, { useEffect, useState, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { fetchFunctionsDropdown } from '../functions/store'

import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import { SpecialCharactersFieldValidion } from '../../../utility/Commonfunction'
import { addGplRole, editGplRole, selectGolRole, setLoader } from './store'
import classNames from 'classnames'
import { fetchDepartmentMasterDropdown } from '../department/store'

const GplRoleForm = ({ setShow }) => {
  const dispatch = useDispatch()
  const { loader, selected, params } = useSelector((state) => state?.gplRole)
  const { departmentMasterDropdown } = useSelector((state) => state?.department)

  const defaultValues = {
    name: '',
    gplDepartmentId: ''
  }

  const {
    reset,
    control,
    setError,
    setValue,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues })

  const handleModalClosed = () => {
    setShow(false)
    reset()
    dispatch(selectGolRole(null))
  }

  const DepartmentOptions = departmentMasterDropdown?.map((masterModule) => {
    return {
      label: masterModule.gplDepartmentName,
      value: masterModule.gplDepartmentId
    }
  })

  const onSubmit = async (data) => {
    let response = ''
    if (selected) {
      await dispatch(setLoader(true))
      response = await dispatch(
        editGplRole({
          id: selected?.gplRoleId,
          name: data.name?.replace(/^\s+|\s+$/g, '').replace(/\s+/g, ' '),
          gplDepartmentId: data.gplDepartmentId?.value,
          params
        })
      )
    } else {
      await dispatch(setLoader(true))
      response = await dispatch(
        addGplRole({
          name: data.name?.replace(/^\s+|\s+$/g, '').replace(/\s+/g, ' '),
          gplDepartmentId: data.gplDepartmentId?.value,
          params
        })
      )
    }
    if (response) {
      handleModalClosed()
    }
  }

  useEffect(() => {
    dispatch(fetchDepartmentMasterDropdown())
    if (selected) {
      if (selected?.gplDepartmentName) {
        setValue('gplDepartmentId', {
          label: selected?.gplDepartmentName,
          value: selected?.gplDepartmentId
        })
      }
      setValue('name', selected?.gplRoleName)
    }
  }, [])

  return (
    <>
      <Modal
        isOpen={true}
        onClosed={handleModalClosed}
        toggle={handleModalClosed}
        className="modal-dialog-centered"
      >
        <ModalHeader
          className="bg-transparent "
          toggle={handleModalClosed}
        ></ModalHeader>
        <ModalBody>
          <div className="text-center mb-0">
            <h1 className="mb-0">
              {selected ? 'Edit ' : 'Add '}
              GPL Role{' '}
            </h1>
          </div>

          <Row tag={Form} className="p-2" onSubmit={handleSubmit(onSubmit)}>
            <Col xs={12} display="flex">
              <div className="mb-1">
                <Label className="form-label" for="name">
                  Role Name <span className="text-danger">*</span>
                </Label>
                <Controller
                  id="name"
                  name="name"
                  control={control}
                  {...register('name', {
                    required: 'Please enter name',
                    maxLength: 100,
                    validate: SpecialCharactersFieldValidion
                    // Add the custom validation rule
                  })}
                  render={({ field }) => (
                    <>
                      <Input
                        placeholder="Enter name"
                        invalid={errors.name && true}
                        {...field}
                      />
                      {errors.name && (
                        <FormFeedback>{errors.name.message}</FormFeedback>
                      )}
                    </>
                  )}
                />
              </div>

              <div className="mb-1">
                <Label className="form-label" for="user">
                  Department <span className="text-danger">*</span>
                </Label>
                <Controller
                  id="gplDepartmentId"
                  name="gplDepartmentId"
                  control={control}
                  rules={{ required: 'Department is required' }}
                  invalid={errors.gplDepartmentId && true}
                  render={({ field }) => (
                    <>
                      <Select
                        isClearable={true}
                        placeholder="select"
                        classNamePrefix="select"
                        options={DepartmentOptions}
                        theme={selectThemeColors}
                        className={classNames('react-select', {
                          'is-invalid': errors.gplDepartmentId
                        })}
                        {...field}
                      />
                    </>
                  )}
                />
                {errors.gplDepartmentId && (
                  <FormFeedback>{errors.gplDepartmentId.message}</FormFeedback>
                )}
              </div>
            </Col>

            <Col xs={12} className="text-center mt-2 mb-1">
              <Button
                className="me-1"
                color="primary"
                type="submit"
                disabled={loader}
              >
                {loader ? (
                  <Spinner text="Loading..." color="white" size="sm" />
                ) : (
                  'Submit'
                )}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}

export default GplRoleForm
