// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl } from '../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'
import { encoded } from '../../../utility/Utils'

export const fetchCareerAspirationList = createAsyncThunk(
  'careerAspirationAdmin/fetchCareerAspirationList',
  async (data) => {
    try {
      const temp = {
        ...data.params
      }
      temp.search = data?.params?.search ? encoded(data?.params?.search) : ''
      const response = await axios.get(baseUrl() + '/career-aspiration/users', {
        params: temp
      })
      return {
        careerAspirationList: response?.data?.data?.map((a) => {
          a.id = a.userId
          return a
        }),
        totalPages: response?.data?.count?.total
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

export const CareerAspirationExport = createAsyncThunk(
  'careerAspirationAdmin/CareerAspirationExport',
  async ({ formData, params }, { dispatch }) => {
    try {
      await axios.post(baseUrl() + '/user/export', formData)
      toast.success('Career Aspiration Exported successfully')
      dispatch(fetchCareerAspirationList({ ...params }))
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

export const careerAspirationDelete = createAsyncThunk(
  'careerAspirationAdmin/careerAspirationDelete',
  async ({ ids, pageNo, pageSize, search }, { dispatch }) => {
    dispatch(setLoader(true))
    try {
      await axios.delete(baseUrl() + `/career-aspiration`, {
        data: { ids }
      })
      toast.success('Career Aspirations deleted successfully')
      dispatch(
        fetchCareerAspirationList({ pageNo: 1, pageSize, search: search })
      )
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const careerAspirationAdmin = createSlice({
  name: 'careerAspirationAdmin',
  initialState: {
    careerAspirationList: [],
    loader: true,
    params: {
      pageNo: 1,
      pageSize: 10,
      search: '',
      export: false,
      userId: '',
      function: ''
    },
    totalPages: 0,
    selected: null,
    filters: {
      department: '',
      function: '',
      blankRecords: '',
      exportIds: ''
    }
  },
  reducers: {
    selectCareerAspiration: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    selectedFilters: (state, action) => {
      if (action.payload === null) {
        state.filters = {
          userId: '',
          function: ''
        }
      } else {
        state.filters = action.payload
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setPageNo: (state, action) => {
      state.params = { ...state.params, pageNo: action.payload }
    },
    setPageSize: (state, action) => {
      state.params = { ...state.params, pageSize: action.payload }
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchCareerAspirationList.fulfilled, (state, action) => {
      state.careerAspirationList = action?.payload?.careerAspirationList
      state.totalPages = action?.payload?.totalPages
      state.loader = false
    })
  }
})

export const {
  selectCareerAspiration,
  setLoader,
  setPageNo,
  setPageSize,
  setSearch,
  selectedFilters
} = careerAspirationAdmin.actions

export default careerAspirationAdmin.reducer
