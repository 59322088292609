// ** React Imports
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Col,
  Form,
  Input,
  Label,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import Select from 'react-select'
import { selectThemeColors } from '@utils'
import { useForm, Controller } from 'react-hook-form'
import { getEnrollData, selectEnroll, selectedFilters } from './store'
import { fetchUserList } from '../masters/user/store'
import { fetchLearningList } from '../masters/learning-tracks/store'
import { setPageNo } from './store'

const defaultValues = {
  email: '',
  name: [],
  status: '',
  track: []
}

const FilterFormEnroll = ({
  params,
  filter,
  filters,
  handleFilterClosed,
  setFilter,
  setClearFilter,
  setSelected
}) => {
  //   // ** States
  const dispatch = useDispatch()
  const { selected } = useSelector((state) => state?.enroll)
  const { userList } = useSelector((state) => state.userMaster)
  const { learningList } = useSelector((state) => state?.learningTrack)
  const [error, setError] = useState(false)
  const { reset, control, handleSubmit, setValue } = useForm({
    defaultValues: selected === null ? defaultValues : selected
  })

  const userOptions = userList?.map((status) => {
    return {
      label: status.name,
      value: status.id
    }
  })

  const trackOptions = learningList?.map((status) => {
    return {
      label: status.name,
      value: status.id
    }
  })

  const statusOptions = [
    { label: 'ACCEPT', value: '0' },
    { label: 'REJECT', value: '1' },
    { label: 'HOLD', value: '2' },
    { label: 'SUBMITTED', value: '3' }
  ]

  const handleFilter = (formData) => {
    dispatch(setPageNo(1))
    if (
      formData?.name?.length === 0 &&
      formData?.email?.length === 0 &&
      formData?.status?.length === 0 &&
      formData?.track?.length === 0
    ) {
      setError(true)
    } else {
      dispatch(selectEnroll(formData))

      const payload = {
        name:
          formData.name === ''
            ? ''
            : formData?.name?.map((temp) => temp.label).join(','),
        email: formData?.email,
        track:
          formData.track === ''
            ? ''
            : formData?.track?.map((temp) => temp.value).join(','),
        status: formData?.status ? formData?.status.value : ''
      }
      dispatch(
        getEnrollData({
          params: {
            pageNo: 1,
            pageSize: params.pageSize,
            search: ''
          },
          filters: payload
        })
      )
      dispatch(selectedFilters(payload))
      setFilter(false)
      setClearFilter(true)
    }
  }

  const handleReset = () => {
    setClearFilter(false)
    dispatch(
      getEnrollData({
        params: {
          pageNo: 1,
          pageSize: 10,
          search: '',
          export: false
        },
        filters: filters
      })
    )
    dispatch(setSelected(null))
    reset()
  }

  useEffect(() => {
    dispatch(fetchUserList())
    dispatch(fetchLearningList())
  }, [])
  return (
    <>
      <Modal
        isOpen={filter}
        onClosed={handleFilterClosed}
        toggle={() => setFilter(!filter)}
        fullscreen="lg"
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader
          className="bg-transparent "
          toggle={() => setFilter(!filter)}
        ></ModalHeader>
        <ModalBody>
          <div className="text-center mb-0">
            <h1 className="mb-1 ">Filters</h1>
          </div>

          <Row tag={Form} onSubmit={handleSubmit((data) => handleFilter(data))}>
            <Col
              xs={12}
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                columnGap: '10px'
              }}
            >
              <div className="mb-1">
                <Label className="form-label" for="status">
                  Filter By User
                </Label>
                <Controller
                  id="name"
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isClearable={false}
                      classNamePrefix="select"
                      isMulti
                      options={userOptions}
                      theme={selectThemeColors}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="mb-1">
                <Label className="form-label" for="Users">
                  Filter by Email
                </Label>
                <Controller
                  id="email"
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Enter Email"
                      onChange={(e) => setValue('email', e.target.value)}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="mb-1">
                <Label className="form-label" for="Users">
                  Filter by Status
                </Label>
                <Controller
                  id="status"
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isClearable={false}
                      classNamePrefix="select"
                      options={statusOptions}
                      theme={selectThemeColors}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="mb-1">
                <Label className="form-label" for="track">
                  Filter By Track
                </Label>
                <Controller
                  id="track"
                  name="track"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isClearable={false}
                      classNamePrefix="select"
                      options={trackOptions}
                      isMulti
                      theme={selectThemeColors}
                      {...field}
                    />
                  )}
                />
              </div>
            </Col>
            <Col xs={12} className="text-center mt-2 mb-1">
              <Button className="me-1" color="primary" type="submit">
                Filter
              </Button>
              <Button outline type="reset" onClick={handleReset}>
                Clear
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}

export default FilterFormEnroll
