import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap'
import { addHomePdf, setLoader } from './store'
import { Trash2 } from 'react-feather'

const defaultValues = {
  trackImg: ''
}

const DefaultTrackThumbnail = ({ open, setOpen, keyId }) => {
  const { homeList, loader, selected, params } = useSelector(
    (state) => state?.home
  )

  const {
    reset,
    control,
    setError,
    setValue,
    getValues,
    trigger,
    register,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues })

  const dispatch = useDispatch()
  const [file, setFile] = useState('')
  const [trackImg, setTrackImg] = useState('')
  const [updateFlag, setUpdateFlag] = useState(false)

  const handleModalClosed = () => {
    setFile('')
    setOpen(false)
    reset()
    setUpdateFlag(false)
  }

  const handlearchitectureImgWeb = async (data) => {
    const result = await trigger('trackImg')
    if (result) {
      dispatch(setLoader(true))
      let response = ''
      const formData = new FormData()
      formData.append('file', file),
        formData.append('key', 'DEFAULT_TRACK_IMAGE'),
        (response = await dispatch(
          addHomePdf({
            formData
          })
        ))
      setOpen(false)
      dispatch(setLoader(false))
    }
  }

  useEffect(() => {
    let getImg = ''
    homeList.map((e) => {
      if (e.key === 'DEFAULT_TRACK_IMAGE') {
        getImg = e.originalName
      }
    })
    setValue('trackImg', getImg)
  }, [homeList])

  const handleRemovedImgWebFiles = () => {
    setTrackImg('')
    setValue('trackImg', '')
    setUpdateFlag(true)
  }

  useEffect(() => {
    setTrackImg(getValues('trackImg'))
  }, [])

  return (
    <>
      <Modal
        isOpen={open}
        onClosed={handleModalClosed}
        toggle={handleModalClosed}
        className="modal-dialog-centered"
      >
        <ModalHeader
          className="bg-transparent "
          toggle={handleModalClosed}
        ></ModalHeader>

        <ModalBody>
          <div className="text-center mb-0">
            <h1 className="mb-1"> Update Default Track Image</h1>
          </div>

          <Row tag={Form} className="p-0 mb-4 archtAlchemy">
            <Row className="architectureImg">
              {!trackImg ? (
                <Col xs={8} display="flex">
                  <Label className="form-label" for="url">
                    Default Thumbnail Upload (.png, .jpeg, .jpg)
                    <span className="text-danger">*</span>
                  </Label>
                  <Controller
                    id="trackImg"
                    name="trackImg"
                    control={control}
                    render={({ field }) => (
                      <Input
                        type="file"
                        accept="image/png,image/jpeg"
                        name="trackImg"
                        files={file}
                        {...register('trackImg', {
                          required: 'Please upload file',
                          validate: (value) =>
                            value.type === 'image/jpeg' ||
                            value.type === 'image/png' || (
                              <p style={{ marginBottom: '0px !important' }}>
                                Please select valid format
                              </p>
                            )
                        })}
                        invalid={errors.trackImg && true}
                        onChange={(e) => {
                          setFile(e.target.files[0])
                          setValue('trackImg', e.target.files[0])
                          dispatch(setLoader(false))
                        }}
                      />
                    )}
                  />
                </Col>
              ) : (
                <Col xs={8} display="flex">
                  <Label className="form-label d-flex" for="fileUrl">
                    File Name
                  </Label>
                  <div className="d-flex ">
                    <Controller
                      name="trackImg"
                      control={control}
                      render={({ field }) => (
                        <Input name="trackImg" {...field} />
                      )}
                    />
                    <Button
                      color="danger"
                      outline
                      size="sm"
                      className="btn-icon ms-1"
                      onClick={() => handleRemovedImgWebFiles()}
                    >
                      <Trash2 size={14} />
                    </Button>
                  </div>
                </Col>
              )}

              <Col xs={4} mb-1 style={errors.trackImg}>
                {file && !errors.trackImg ? (
                  <Button
                    className="me-1"
                    color="primary"
                    onClick={handlearchitectureImgWeb}
                  >
                    {loader ? (
                      <Spinner
                        style={{ height: '10px', width: '10px' }}
                        size={2}
                        color="white"
                      />
                    ) : (
                      'Submit'
                    )}
                  </Button>
                ) : (
                  <Button
                    className="me-1"
                    color="primary"
                    onClick={handlearchitectureImgWeb}
                    disabled={loader || !updateFlag}
                  >
                    Submit
                  </Button>
                )}
              </Col>

              {errors && errors.trackImg && (
                <FormFeedback>{errors.trackImg.message}</FormFeedback>
              )}
            </Row>
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}

export default DefaultTrackThumbnail
