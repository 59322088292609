import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import '@styles/react/libs/editor/editor.scss'
import '@styles/base/plugins/forms/form-quill-editor.scss'
import htmlToDraft from 'html-to-draftjs'
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap'
import { addFooterText } from './store'
import draftToHtml from 'draftjs-to-html'
const defaultValues = {
  text: '',
  description: ''
}

const GplInroForm = ({ show, setShow, keyId }) => {
  const { homeList, loader, selected, params } = useSelector(
    (state) => state?.home
  )
  const dispatch = useDispatch()

  const [descriptionContent, setDescriptionContent] = useState(
    EditorState.createEmpty()
  )

  const {
    reset,
    control,
    getValues,
    setError,
    setValue,
    register,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues })

  const handleModalClosed = () => {
    setShow(false)
    reset()
  }

  const onSubmit = (data) => {
    // let text = getValues('description').blocks.map((e) => e.text)

    const descriptionData = draftToHtml(
      convertToRaw(descriptionContent.getCurrentContent())
    )
    if (descriptionData === '<p></p>\n') {
      return
    }
    const params = {
      footerText: descriptionData,
      key: 'GPL_INTRO_TEXT'
    }
    dispatch(addFooterText({ params }))
    setShow(false)
  }

  useEffect(() => {
    let description = ''
    homeList?.map((e) => {
      if (e.key === 'GPL_INTRO_TEXT') {
        description = e.text
      }
    })
    //setValue('description', description)
    if (description) {
      const descriptionFromHtml = htmlToDraft(description)
      const descriptionState = ContentState.createFromBlockArray(
        descriptionFromHtml.contentBlocks,
        descriptionFromHtml.entityMap
      )
      const newDescriptionState =
        EditorState.createWithContent(descriptionState)
      setDescriptionContent(newDescriptionState)
      setValue('description', descriptionContent)
    }
  }, [homeList])

  return (
    <>
      <Modal
        isOpen={show}
        onClosed={handleModalClosed}
        className="modal-dialog-centered"
      >
        <ModalHeader
          className="bg-transparent "
          toggle={handleModalClosed}
        ></ModalHeader>

        <ModalBody>
          <div className="text-center mb-0">
            <h1 className="mb-0"> Update GPL Alchemy Introduction</h1>
          </div>

          <Row tag={Form} className="p-2" onSubmit={handleSubmit(onSubmit)}>
            <Col xs={12}>
              <div className="mb-1">
                <Label className="form-label">
                  Description<span className="text-danger">*</span>
                </Label>
                <Controller
                  id="description"
                  name="description"
                  control={control}
                  defaultValue={EditorState.createEmpty()}
                  rules={{
                    validate: (value) => {
                      let text = ''
                      text = value?.blocks?.[0]?.text.trim()
                      if (text === '' && text.length == 0) {
                        return 'This field is required'
                      }
                      if (
                        text?.includes('\n') ||
                        text?.includes('<br>') ||
                        text?.includes('&nbsp;')
                      ) {
                        return 'This field should not contain newline characters or line breaks.'
                      }
                      return undefined
                    }
                  }}
                  render={({ field }) => (
                    <Editor
                      editorState={descriptionContent}
                      onEditorStateChange={(data) => {
                        setValue('description', data)
                        setDescriptionContent(data)
                      }}
                      placeholder="Enter Description"
                      editorClassName="rounded-1 border-1"
                      toolbar={{
                        options: ['inline', 'textAlign', 'list'],
                        textAlign: { inDropdown: false },
                        list: { inDropdown: false },
                        bold: { className: 'bold-font-css' },
                        inline: {
                          inDropdown: false,
                          options: [
                            'bold',
                            'italic',
                            'underline',
                            'strikethrough'
                          ]
                        }
                      }}
                      {...register('description', {
                        required: 'Please enter discription'
                      })}
                      invalid={errors.description ? true : false}
                      {...field}
                    />
                  )}
                />
                {errors && errors.description && (
                  <FormFeedback>{errors.description.message}</FormFeedback>
                )}
              </div>
            </Col>

            <Col xs={12} className="text-center mt-2 mb-1">
              <Button className="me-1" color="primary" type="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}

export default GplInroForm
