import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'

// ** Reactstrap Imports
import { Button, Card } from 'reactstrap'
import AlchemyYourselfForm from './AlchemyYourselfForm'
import ArchitectureImgForm from './ArchitectureImgForm'
import GplIntroForm from './GplIntroForm'

// ** Table Import
import Table from './Table'
import GCFImageFrom from './GCFImageForm'
import DefaultTrackThumbnail from './DefaultTrackThumbnail'
import { useMemo } from 'react'

const Home = () => {
  const { homeList, loader, totalPages, selected, params } = useSelector(
    (state) => state?.home
  )
  const [isOpen, setIsOpen] = useState(false)
  const [show, setShow] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const [open, setOpen] = useState(false)
  const [openForm, setOpenForm] = useState(false)

  const handleAlchemyize = () => {
    setIsOpen(true)
  }

  const handleGplIntro = () => {
    setShow(true)
  }

  const handleArchitectureImg = () => {
    setIsShow(true)
  }

  const handleGCFImage = () => {
    setOpen(true)
  }
  return (
    <Fragment>
      <Card>
        <div className="card-datatable app-user-list table-responsive">
          {useMemo(() => (
            <Table />
          ))}
        </div>
      </Card>

      <Card className="p-2 card-datatable">
        <div className="chroAdmin">
          <p className="acceptCA">GPL Alchemy Introduction</p>
          <Button
            color="primary"
            className="addBanner mb-sm-0"
            onClick={handleGplIntro}
          >
            Update Detail
          </Button>
        </div>

        <div className="chroAdmin alchemyYourself">
          <p className="acceptCA">Alchemy-ize Yourself</p>
          <Button
            color="primary"
            className="addBanner mb-sm-0"
            onClick={handleAlchemyize}
          >
            Update Detail
          </Button>
        </div>

        <div className="chroAdmin">
          <p className="acceptCA">Academy Architecture</p>
          <Button
            color="primary"
            className="addBanner mb-sm-0"
            onClick={handleArchitectureImg}
          >
            Update Detail
          </Button>
        </div>

        <div className="chroAdmin mt-2">
          <p className="acceptCA">GCF Image</p>
          <Button
            color="primary"
            className="addBanner mb-sm-0"
            onClick={handleGCFImage}
          >
            Update Detail
          </Button>
        </div>

        <div className="chroAdmin mt-2">
          <p className="acceptCA">Default Track Image</p>
          <Button
            color="primary"
            className="addBanner mb-sm-0"
            onClick={() => setOpenForm(true)}
          >
            Update Detail
          </Button>
        </div>
      </Card>

      <AlchemyYourselfForm isOpen={isOpen} setIsOpen={setIsOpen} />
      <GplIntroForm show={show} setShow={setShow} />
      {isShow && <ArchitectureImgForm isShow={isShow} setIsShow={setIsShow} />}
      {open && <GCFImageFrom open={open} setOpen={setOpen} />}
      {openForm && (
        <DefaultTrackThumbnail open={openForm} setOpen={setOpenForm} />
      )}
    </Fragment>
  )
}

export default Home
