import { Switch } from '@mui/material'
import { React, useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from 'react-data-table-component'
import { ChevronDown } from 'react-feather'
import { Button } from 'reactstrap'
import Spinner from '../../components/spinner/Loading-spinner'
import CustomHeader from '../../utility/commonComponents/CustomHeader'
import {
  fetchHomeList,
  setLoader,
  setPageNo,
  setSearch,
  updateHomeStatus
} from './store'
import { OverlayTrigger } from 'react-bootstrap'
import Tooltip from '@mui/material/Tooltip'
import FileView from '../masters/talent-philosophy/FileView'
import ReplaceModal from './ReplaceModal'
import '../../../src/common.css'

function Table() {
  const { homeList, loader, selected, params } = useSelector(
    (state) => state?.home
  )

  const [pdfView, setPdfView] = useState('')
  const { pageNo, pageSize, search } = params
  const [show, setShow] = useState(false)
  const [keyId, setKeyId] = useState()
  const [fileUrl, setFileUrl] = useState()
  const [fileShow, setFileShow] = useState(false)
  const dispatch = useDispatch()

  const dataToRender = () => {
    return finalFilterArray
  }

  const handleFileView = (fileName) => {
    // dispatch(selectHome(fileName))
    setPdfView(fileName)
    setFileShow(true)
  }

  const filterArray1 = homeList?.filter((e) => e.key === 'FAQ_PDF') ?? []
  const filterArray2 = homeList?.filter((e) => e.key === 'POLICY_PDF') ?? []
  const filterArray3 = homeList?.filter((e) => e.key === 'ABOUT_US_PDF') ?? []
  let finalFilterArray = []
  if (filterArray1.length !== 0 && filterArray2 !== 0 && filterArray3 !== 0) {
    finalFilterArray = [
      { ...filterArray1?.[0], label: 'Current FAQ Document' },
      { ...filterArray2?.[0], label: 'Current Policy Document' },
      { ...filterArray3?.[0], label: 'Current About Us Document' }
    ]
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setLoader(true))
      dispatch(
        fetchHomeList({
          pageNo: 1,
          pageSize,
          search: search
        })
      )
      dispatch(setPageNo(1))
    }, 1000)
    return () => {
      clearTimeout(timer)
    }
  }, [search])

  useEffect(() => {
    dispatch(setSearch(''))
  }, [])

  const handleReplaceOpen = (row) => {
    setShow(true)
    setKeyId(row.key)
    setFileUrl(row.originalName)
  }

  const handleChange = async (e) => {
    let params = {
      pageNo: 1,
      pageSize: 10,
      search: ''
    }
    dispatch(
      updateHomeStatus({
        payload: {
          flag: !e.flag,
          key: e.key
        },
        params
      })
    )
  }

  const formatDate = (dateStr) => {
    const date = new Date(dateStr)

    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()

    const formattedDate = `${day}-${month}-${year}`

    return formattedDate
  }

  const columns = [
    {
      sortable: false,
      maxWidth: '250px',
      name: 'Name',
      selector: 'label'
    },
    {
      sortable: false,
      // width: '30%',
      name: 'Document',
      cell: (row) =>
        row?.fileName?.length > 50 ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className="careerAspiration-tooltip" arrow>
                <div>
                  {row.fileName +
                    (row.updatedAt ? '_' + formatDate(row.updatedAt) : '')}
                </div>
              </Tooltip>
            }
          >
            <span
              aria-describedby="toolTip"
              className=" table_row1 careerAspiration"
            >
              {row.fileName +
                (row.updatedAt ? '_' + formatDate(row.updatedAt) : '')}
            </span>
          </OverlayTrigger>
        ) : (
          <>
            {row.fileName ? (
              <span>
                {row.fileName +
                  (row.updatedAt ? '_' + formatDate(row.updatedAt) : '')}
              </span>
            ) : (
              '-'
            )}
          </>
        )
    },
    {
      name: 'Status',
      maxWidth: '80px',
      cell: (row) => {
        return (
          <div className="d-flex align-items-center permissions-actions">
            <Switch checked={row?.flag} onChange={() => handleChange(row)} />
          </div>
        )
      }
    },
    {
      name: 'Action',
      // width: '20%',
      cell: (row) => {
        return (
          <div className="d-flex align-items-center permissions-actions btnstyle">
            <Button
              className="add-permission "
              color="primary"
              onClick={() => handleFileView(row.originalName)}
            >
              View
            </Button>

            <Button
              className="add-permission"
              color="primary"
              onClick={() => handleReplaceOpen(row)}
            >
              Replace
            </Button>
          </div>
        )
      }
    }
  ]
  return (
    <>
      <div className="react-dataTable homeTable">
        <DataTable
          // selectableRows
          subHeader
          responsive
          highlightOnHover
          progressComponent={<Spinner />}
          progressPending={loader}
          columns={columns}
          data={dataToRender()}
          sortIcon={<ChevronDown />}
          className="react-dataTable"
          subHeaderComponent={
            <CustomHeader
              addClick={false}
              handleSearch={(temp) => dispatch(setSearch(temp))}
              title="Home List"
              // permissions={hasPermission('Footer_Document_List')}
            />
          }
        />
        {fileShow && (
          <FileView
            fileShow={fileShow}
            setFileShow={setFileShow}
            getFileName={pdfView}
          />
        )}
      </div>
      {show && (
        <ReplaceModal
          show={show}
          setShow={setShow}
          keyId={keyId}
          fileUrl={fileUrl}
        />
      )}
    </>
  )
}

export default Table
