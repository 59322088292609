import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'

import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import '@styles/react/libs/editor/editor.scss'
import '@styles/base/plugins/forms/form-quill-editor.scss'
import { imageRegex } from '../../utility/Utils'
import { addFooterText, addHomePdf } from './store'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'

const defaultValues = {
  imageUrl: '',
  leftDescription: '',
  rightDescription: ''
}

function AlchemyYourselfForm({ isOpen, setIsOpen, isClose, keyId }) {
  const { homeList, loader, selected, params } = useSelector(
    (state) => state?.home
  )

  const [leftDescriptionError, setLeftDescriptionError] = useState('')
  const [rightDescriptionError, setRightDescriptionError] = useState('')
  const dispatch = useDispatch()
  const [leftDescriptionContent, setleftDescriptionContent] = useState(
    EditorState.createEmpty()
  )
  const [rightDescriptionContent, setRightDescriptionContent] = useState(
    EditorState.createEmpty()
  )

  const {
    reset,
    control,
    getValues,
    setError,
    setValue,
    register,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues })

  const handleLeftDescription = async (data) => {
    if (
      convertToRaw(
        leftDescriptionContent.getCurrentContent()
      )?.blocks?.[0].text.trim() === ''
    ) {
      setLeftDescriptionError('This field is required')
    } else {
      const leftDescriptionData = draftToHtml(
        convertToRaw(leftDescriptionContent.getCurrentContent())
      )
      const params = {
        footerText: leftDescriptionData,
        key: 'ALCHEMIZE_LEFT_TEXT'
      }
      dispatch(addFooterText({ params }))
    }
  }

  const handleRightDescription = async (data) => {
    if (
      convertToRaw(
        rightDescriptionContent.getCurrentContent()
      )?.blocks?.[0].text.trim() === ''
    ) {
      setRightDescriptionError('This field is required')
    } else {
      const rightDescriptionData = draftToHtml(
        convertToRaw(rightDescriptionContent.getCurrentContent())
      )
      const params = {
        footerText: rightDescriptionData,
        key: 'ALCHEMIZE_RIGHT_TEXT'
      }
      dispatch(addFooterText({ params }))
    }
  }

  const handleModalClosed = () => {
    setIsOpen(false)
    reset()
  }

  useEffect(() => {
    let left = ''
    let right = ''
    homeList?.map((e) => {
      if (e.key === 'ALCHEMIZE_LEFT_TEXT') {
        left = e.text
      } else if (e.key === 'ALCHEMIZE_RIGHT_TEXT') {
        right = e.text
      }
    })
    setValue('leftDescription', left)
    setValue('rightDescription', right)
    if (left) {
      const leftDescriptionFromHtml = htmlToDraft(left)
      const leftDescriptionState = ContentState.createFromBlockArray(
        leftDescriptionFromHtml.contentBlocks,
        leftDescriptionFromHtml.entityMap
      )
      const newLeftDescriptionState =
        EditorState.createWithContent(leftDescriptionState)
      setleftDescriptionContent(newLeftDescriptionState)
      setValue('left', leftDescriptionContent)
    }

    if (right) {
      const rightDescriptionFromHtml = htmlToDraft(right)
      const rightDescriptionState = ContentState.createFromBlockArray(
        rightDescriptionFromHtml.contentBlocks,
        rightDescriptionFromHtml.entityMap
      )
      const newRightDescriptionState = EditorState.createWithContent(
        rightDescriptionState
      )
      setRightDescriptionContent(newRightDescriptionState)
      setValue('right', rightDescriptionContent)
    }
  }, [homeList])

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClosed={handleModalClosed}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader
          className="bg-transparent "
          toggle={handleModalClosed}
        ></ModalHeader>

        <ModalBody>
          <div className="text-center mb-0">
            <h1 className="mb-0">Update Alchemy-ize Yourself </h1>
          </div>

          <Row tag={Form} className="p-2">
            <Col xs={6}>
              <div className="mb-1">
                <Label className="form-label">
                  Left Description<span className="text-danger">*</span>
                </Label>
                <Controller
                  id="leftDescription"
                  name="leftDescription"
                  control={control}
                  // rules={{
                  //   validate:{
                  //     required: "reuired"
                  //   }
                  // }}
                  render={({ field }) => (
                    <Editor
                      editorState={leftDescriptionContent}
                      onEditorStateChange={(data) => {
                        setValue('leftDescription', data)
                        setleftDescriptionContent(data)
                      }}
                      placeholder="Enter Left Description"
                      editorClassName="rounded-1 border-1"
                      toolbar={{
                        options: ['inline', 'textAlign', 'list'],
                        textAlign: { inDropdown: false },
                        list: { inDropdown: false },
                        bold: { className: 'bold-font-css' },
                        inline: {
                          inDropdown: false,
                          options: [
                            'bold',
                            'italic',
                            'underline',
                            'strikethrough'
                          ]
                        }
                      }}
                      {...register('leftDescription', {
                        required: 'Please enter Left Description'
                      })}
                      invalid={errors.leftDescription ? true : false}
                      {...field}
                    />
                  )}
                />
                {convertToRaw(leftDescriptionContent.getCurrentContent())
                  ?.blocks?.[0].text === '' && (
                  <FormFeedback>{leftDescriptionError}</FormFeedback>
                )}
              </div>
            </Col>

            <Col xs={6}>
              <div className="mb-1">
                <Label className="form-label">
                  Right Description<span className="text-danger">*</span>
                </Label>
                <Controller
                  id="rightDescription"
                  name="rightDescription"
                  control={control}
                  // rules={{
                  //   validate:{
                  //     required: "reuired"
                  //   }
                  // }}
                  render={({ field }) => (
                    <Editor
                      editorState={rightDescriptionContent}
                      onEditorStateChange={(data) => {
                        setValue('rightDescription', data)
                        setRightDescriptionContent(data)
                      }}
                      placeholder="Enter Right Description"
                      editorClassName="rounded-1 border-1"
                      toolbar={{
                        options: ['inline', 'textAlign', 'list'],
                        textAlign: { inDropdown: false },
                        list: { inDropdown: false },
                        bold: { className: 'bold-font-css' },
                        inline: {
                          inDropdown: false,
                          options: [
                            'bold',
                            'italic',
                            'underline',
                            'strikethrough'
                          ]
                        }
                      }}
                      {...register('text', {
                        required: 'Please enter Right Description'
                      })}
                      invalid={errors.rightDescription ? true : false}
                      {...field}
                    />
                  )}
                />
                {convertToRaw(rightDescriptionContent.getCurrentContent())
                  ?.blocks?.[0].text === '' && (
                  <FormFeedback>{rightDescriptionError}</FormFeedback>
                )}
              </div>
            </Col>

            <Col xs={6} className="text-center mt-2 mb-1">
              <Button
                className="me-1"
                color="primary"
                onClick={handleLeftDescription}
              >
                Submit
              </Button>
            </Col>

            <Col xs={6} className="text-center mt-2 mb-1">
              <Button
                className="me-1"
                color="primary"
                onClick={handleRightDescription}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}

export default AlchemyYourselfForm
