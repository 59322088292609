import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../app.config'
import { encoded } from '../../../utility/Utils'

export const getEnrollData = createAsyncThunk(
  'enroll/getEnrollData',
  async ({ params, filters }, { dispatch }) => {
    try {
      const response = await axios.post(
        baseUrl() +
          `/user-track?pageNo=${params.pageNo}&pageSize=${
            params.pageSize
          }&search=${encoded(params.search)}`,
        filters
      )
      return {
        params: response.data.params,
        enrollList: response?.data?.data,
        totalPages: response?.data?.count?.total
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const getEnrollBulkUpload = createAsyncThunk(
  'enroll/getEnrollBulkUpload',
  async ({ formData, params, filters }, { dispatch }) => {
    try {
      await axios.post(baseUrl() + '/enrollNow/upload', formData)
      toast.success('Enroll BulkUploaded successfully')
      dispatch(getEnrollData({ params, filters }))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const getEnrollStatus = createAsyncThunk(
  'enroll/getEnrollStatus',
  async (payload, { dispatch }) => {
    dispatch(setLoader(true))
    let enrollStatus = payload.params.enrollStatus
    try {
      await axios.patch(baseUrl() + `/user-track/${payload.id}`, {
        enrollStatus
      })

      toast.success(
        `${
          payload.params.enrollStatus === 'ACCEPT'
            ? 'Accepted'
            : payload.params.enrollStatus === 'HOLD'
            ? 'Held'
            : 'Rejected'
        } successfully`
      )
      const { params, filters } = payload
      delete params.enrollStatus
      dispatch(getEnrollData({ params, filters }))
      dispatch(setLoader(false))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const addEnroll = createAsyncThunk(
  'enroll/addEnroll',
  async ({ name, params }, { dispatch }) => {
    try {
      await axios.post(baseUrl() + '/enroll', { name })
      toast.success('Enrolled successfully')
      dispatch(getEnrollData({ ...params }))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const editEnroll = createAsyncThunk(
  'enroll/editEnroll',
  async ({ id, name, params }, { dispatch }) => {
    try {
      await axios.put(baseUrl() + `/subTrack/${id}`, { name })
      toast.success('Enroll edited successfully')
      dispatch(getEnrollData({ ...params }))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const deleteEnroll = createAsyncThunk(
  'enroll/deleteEnroll',
  async ({ id, pageNo, pageSize, search }, { dispatch }) => {
    dispatch(setLoader(true))
    try {
      await axios.delete(baseUrl() + `/subTrack/${id}`)
      toast.success('Enroll deleted successfully')
      dispatch(getEnrollData({ pageNo, pageSize, search: search }))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const editEnrollStatus = createAsyncThunk(
  'enroll/editEnrollStatus',
  async ({ payload, param }, { dispatch }) => {
    try {
      const response = await axios.put(
        baseUrl() + `/user-track/status`,
        payload
      )
      if (response?.data == '') {
        toast.error('Action freezed. Cannot update')
      } else {
        toast.success(
          `${
            payload.status === 'ACCEPT'
              ? 'Accepted'
              : payload.status === 'HOLD'
              ? 'Held'
              : 'Rejected'
          } successfully`
        )
      }
      dispatch(getEnrollData({ ...param }))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const enroll = createSlice({
  name: 'enroll',
  initialState: {
    enrollList: [],
    loader: false,
    params: {
      pageNo: 1,
      pageSize: 10,
      search: ''
    },
    filters: {
      email: '',
      name: '',
      status: '',
      track: ''
    },
    totalPages: 0,
    selected: null
  },
  reducers: {
    selectEnroll: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    selectedFilters: (state, action) => {
      if (action.payload === null) {
        state.filters = {
          email: '',
          name: '',
          status: '',
          track: ''
        }
      } else {
        state.filters = { ...state.filters, ...action.payload }
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setPageNo: (state, action) => {
      state.params = { ...state.params, pageNo: action.payload }
    },
    setPageSize: (state, action) => {
      state.params = { ...state.params, pageSize: action.payload }
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEnrollData.fulfilled, (state, action) => {
      state.enrollList = action.payload.enrollList
      state.totalPages = action?.payload?.totalPages
      state.loader = false
    })
  }
})

export const {
  selectEnroll,
  setLoader,
  setPageNo,
  setPageSize,
  setSearch,
  selectedFilters
} = enroll.actions

export default enroll.reducer
