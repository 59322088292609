// ** React Imports
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Col,
  Form,
  Input,
  Label,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  Row,
  FormFeedback
} from 'reactstrap'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import Select from 'react-select'
import Flatpickr from 'react-flatpickr'
import { selectThemeColors } from '@utils'
import { useForm, Controller } from 'react-hook-form'
import {
  getAttendanceData,
  selectAttendance,
  selectedFilters,
  setPageNo
} from './store'
import { fetchFunctionList } from '../masters/functions/store'
import { fetchSubTrackList } from '../masters/learning-tracks/trackDetails/store'
import { fetchLearningList } from '../masters/learning-tracks/store'
import { getNextDay } from '../../utility/Utils'
import { fetchUserList } from '../masters/user/store'
import './Attendance.scss'

const defaultValues = {
  edp: '',
  userId: [],
  email: '',
  function: [],
  zone: '',
  region: '',
  level: '',
  grade: '',
  position: '',
  track: [],
  subtrack: [],
  subtrackCompleteDate: '',
  starPerformer: '',
  status: ''
}

const FilterForm = ({
  params,
  filter,
  filters,
  handleFilterClosed,
  setFilter,
  setClearFilter,
  setSelected
}) => {
  //   // ** States
  const dispatch = useDispatch()
  const { selected } = useSelector((state) => state?.attendance)
  const { functionList } = useSelector((state) => state?.functions)
  const { userList } = useSelector((state) => state.userMaster)
  const { learningList } = useSelector((state) => state?.learningTrack)
  const { subTrackList } = useSelector((state) => state?.subTrack)
  const [error, setError] = useState(false)
  let startDateFiled = { dateFormat: 'Y-m-d' }
  const { reset, control, handleSubmit, setValue } = useForm({
    defaultValues: selected === null ? defaultValues : selected
  })

  const userOptions = userList?.map((status) => {
    return {
      label: status.name,
      value: status.id
    }
  })

  const subtrackOptions = subTrackList?.map((status) => {
    return {
      label: status.subtrackName,
      value: status.subtrackId
    }
  })

  const functionOptions = functionList?.map((func) => {
    return {
      label: func.name,
      value: func.id
    }
  })

  const trackOptions = learningList?.map((status) => {
    return {
      label: status.name,
      value: status.id
    }
  })

  const starOptions = [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false' }
  ]

  const statusOptions = [
    { label: 'PRESENT', value: 0 },
    { label: 'ABSENT', value: 1 },
    { label: 'NO ATTENDANCE', value: 2 }
  ]
  const handleFilter = (formData) => {
    dispatch(setPageNo(1))
    if (
      formData?.edp?.length === 0 &&
      formData?.userId?.length === 0 &&
      formData?.email?.length === 0 &&
      formData?.function?.length === 0 &&
      formData?.zone?.length === 0 &&
      formData?.region?.length === 0 &&
      formData?.level?.length === 0 &&
      formData?.grade?.length === 0 &&
      formData?.position?.length === 0 &&
      formData?.track?.length === 0 &&
      formData?.subtrack?.length === 0 &&
      formData?.subtrackCompleteDate?.length === 0 &&
      formData?.starPerformer?.length === 0 &&
      formData?.status?.length === 0
    ) {
      setError(true)
    } else {
      let formattedDate = ''
      if (formData.subtrackCompleteDate !== '') {
        const dateObj = new Date(formData?.subtrackCompleteDate)
        let inputDate = dateObj.toISOString().slice(0, 10)
        formattedDate = getNextDay(inputDate)
      }

      dispatch(selectAttendance(formData))
      const payload = {
        edp: formData?.edp,
        userId:
          formData.userId === ''
            ? ''
            : formData?.userId?.map((temp) => temp.label).join(','),
        email: formData?.email,
        function:
          formData.function === ''
            ? ''
            : formData?.function?.map((temp) => temp.value).join(','),
        zone: formData?.zone,
        region: formData?.region,
        level: formData?.level,
        grade: formData?.grade,
        position: formData?.position,
        track:
          formData.track === ''
            ? ''
            : formData?.track?.map((temp) => temp.value).join(','),
        subtrack:
          formData.subtrack === ''
            ? ''
            : formData?.subtrack?.map((temp) => temp.value).join(','),
        subtrackCompleteDate: formattedDate,
        starPerformer: formData?.starPerformer
          ? formData?.starPerformer.value
          : '',
        status: formData?.status ? formData?.status.value : ''
      }
      dispatch(
        getAttendanceData({
          params: {
            pageNo: 1,
            pageSize: params.pageSize,
            search: '',
            export: false
          },
          filters: payload
        })
      )
      dispatch(selectedFilters(payload))
      setFilter(false)
      setClearFilter(true)
    }
  }

  const handleReset = () => {
    setClearFilter(false)
    dispatch(
      getAttendanceData({
        params: {
          pageNo: 1,
          pageSize: 10,
          search: '',
          export: false
        },
        filters: filters
      })
    )
    dispatch(setSelected(null))
    reset()
  }

  useEffect(() => {
    dispatch(fetchFunctionList())
    dispatch(fetchSubTrackList())
    dispatch(fetchLearningList())
    dispatch(fetchUserList())
  }, [])
  return (
    <>
      <Modal
        isOpen={filter}
        onClosed={handleFilterClosed}
        toggle={() => setFilter(!filter)}
        className="modal-dialog-centered  modal-lg"
      >
        <ModalHeader
          className="bg-transparent "
          toggle={() => setFilter(!filter)}
        ></ModalHeader>
        <ModalBody style={{ padding: '0.8rem 2.5rem' }}>
          <div className="text-center mb-0">
            <h1 className="mb-1 ">Filters</h1>
          </div>

          <Row tag={Form} onSubmit={handleSubmit((data) => handleFilter(data))}>
            <Col
              xs={12}
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                columnGap: '32px'
              }}
            >
              <div className="mb-1">
                <Label className="form-label" for="Users">
                  Filter by EDP
                </Label>
                <Controller
                  id="edp"
                  name="edp"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Enter EDP"
                      onChange={(e) => setValue('edp', e.target.value)}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="mb-1">
                <Label className="form-label" for="status">
                  Filter By User
                </Label>
                <Controller
                  id="userId"
                  name="userId"
                  control={control}
                  render={({ field }) => (
                    <Select
                      classNamePrefix="select"
                      isMulti
                      options={userOptions}
                      theme={selectThemeColors}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="mb-1">
                <Label className="form-label" for="email">
                  Filter By Email
                </Label>
                <Controller
                  id="email"
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Enter Email"
                      onChange={(e) => setValue('email', e.target.value)}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="mb-1">
                <Label className="form-label" for="function">
                  Filter By Function
                </Label>
                <Controller
                  id="function"
                  name="function"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isClearable={false}
                      classNamePrefix="select"
                      options={functionOptions}
                      isMulti
                      theme={selectThemeColors}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="mb-1">
                <Label className="form-label" for="zone">
                  Filter By Zone
                </Label>
                <Controller
                  id="zone"
                  name="zone"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Enter Zone"
                      onChange={(e) => setValue('zone', e.target.value)}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="mb-1">
                <Label className="form-label" for="region">
                  Filter By Region
                </Label>
                <Controller
                  id="region"
                  name="region"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Enter Region"
                      onChange={(e) => setValue('region', e.target.value)}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="mb-1">
                <Label className="form-label" for="level">
                  Filter By Level
                </Label>
                <Controller
                  id="level"
                  name="level"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Enter Level"
                      onChange={(e) => setValue('level', e.target.value)}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="mb-1">
                <Label className="form-label" for="grade">
                  Filter By Grade
                </Label>
                <Controller
                  id="grade"
                  name="grade"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Enter Grade"
                      onChange={(e) => setValue('grade', e.target.value)}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="mb-1">
                <Label className="form-label" for="position">
                  Filter By Position
                </Label>
                <Controller
                  id="position"
                  name="position"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Enter Position"
                      onChange={(e) => setValue('position', e.target.value)}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="mb-1">
                <Label className="form-label" for="track">
                  Filter By Track
                </Label>
                <Controller
                  id="track"
                  name="track"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isClearable={false}
                      classNamePrefix="select"
                      options={trackOptions}
                      isMulti
                      theme={selectThemeColors}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="mb-1 aniii">
                <Label className="form-label" for="subtrack">
                  Filter By Subtrack
                </Label>
                <Controller
                  id="subtrack"
                  name="subtrack"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isClearable={false}
                      classNamePrefix="select"
                      options={subtrackOptions}
                      isMulti
                      theme={selectThemeColors}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="mb-1">
                <Label className="form-label" for="subtrackCompleteDate">
                  Filter By Complete Date
                </Label>
                <Controller
                  id="subtrackCompleteDate"
                  name="subtrackCompleteDate"
                  control={control}
                  render={({ field }) => (
                    <Flatpickr
                      utc={true}
                      required
                      placeholder="YYYY-MM-DD"
                      className={`form-control flatpickr-input
                       `}
                      onChange={(e) =>
                        setValue('subtrackCompleteDate', e.target.value[0])
                      }
                      {...field}
                      options={startDateFiled}
                    />
                  )}
                />
              </div>
              <div className="mb-1">
                <Label className="form-label" for="starPerformer">
                  Filter By Star Performer
                </Label>
                <Controller
                  id="starPerformer"
                  name="starPerformer"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isClearable={false}
                      classNamePrefix="select"
                      options={starOptions}
                      theme={selectThemeColors}
                      {...field}
                    />
                  )}
                />
                {error && (
                  <FormFeedback>Please Select Atleast One Filter</FormFeedback>
                )}
              </div>
              <div className="mb-1">
                <Label className="form-label" for="status">
                  Filter By Status
                </Label>
                <Controller
                  id="status"
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isClearable={false}
                      classNamePrefix="select"
                      options={statusOptions}
                      theme={selectThemeColors}
                      {...field}
                    />
                  )}
                />
              </div>
            </Col>
            <Col xs={12} className="text-center mt-2 mb-1">
              <Button className="me-1" color="primary" type="submit">
                Filter
              </Button>
              <Button outline type="reset" onClick={handleReset}>
                Clear
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}

export default FilterForm
