import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../app.config'
import { encoded } from '../../../../utility/Utils'

export const fetchGplRole = createAsyncThunk(
  'gplRole/fetchGPLRole',
  async (params, { dispatch }) => {
    try {
      const url = params?.pageNo
        ? `/gpl-role?pageNo=${params.pageNo}&pageSize=${
            params.pageSize
          }&search=${encoded(params.search)}`
        : `/gpl-role`

      const response = await axios.get(baseUrl() + url)
      return {
        gplRoleList: response?.data?.data?.map((a) => {
          a.id = a.gplRoleId
          return a
        }),
        totalPages: response?.data?.count?.total
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

export const fetchGplRoleById = createAsyncThunk(
  'gplRole/fetchGplRoleById',
  async ({ id }, { dispatch }) => {
    console.log('id', id)
    try {
      const response = await axios.get(baseUrl() + `/gpl-role/${id}`)
      return {
        gplRoleListById: response?.data?.data
        // totalPages: response?.data?.count?.total
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

export const addGplRole = createAsyncThunk(
  'gplRole/addGplRole',
  async ({ name, gplDepartmentId, params }, { dispatch }) => {
    try {
      await axios.post(baseUrl() + '/gpl-role', {
        name,
        gplDepartmentId
      })
      toast.success('Role added successfully')
      dispatch(fetchGplRole({ ...params }))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const editGplRole = createAsyncThunk(
  'gplRole/editGplRole',
  async ({ id, name, gplDepartmentId, params }, { dispatch }) => {
    try {
      await axios.put(baseUrl() + `/gpl-role/${id}`, {
        name,
        gplDepartmentId
      })
      toast.success('Role edited successfully')
      dispatch(fetchGplRole({ ...params }))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const deleteGplRole = createAsyncThunk(
  'gplRole/deleteGplRole',
  async ({ ids, pageNo, pageSize, search }, { dispatch }) => {
    dispatch(setLoader(true))
    try {
      await axios.delete(baseUrl() + `/gpl-role`, {
        data: { ids }
      })
      toast.success('Role deleted successfully')
      dispatch(fetchGplRole({ pageNo: 1, pageSize, search: search }))
      dispatch(setPageNo(1))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const department = createSlice({
  name: 'gplRole',
  initialState: {
    gplRoleList: [],
    gplRoleListById: [],
    loader: true,
    params: {
      pageNo: 1,
      pageSize: 10,
      search: ''
    },
    totalPages: 0,
    selected: null
  },
  reducers: {
    selectGolRole: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setPageNo: (state, action) => {
      state.params = { ...state.params, pageNo: action.payload }
    },
    setPageSize: (state, action) => {
      state.params = { ...state.params, pageSize: action.payload }
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGplRole.fulfilled, (state, action) => {
        state.gplRoleList = action?.payload?.gplRoleList
        state.totalPages = action?.payload?.totalPages
        state.loader = false
      })
      .addCase(fetchGplRoleById.fulfilled, (state, action) => {
        state.gplRoleListById = action?.payload?.gplRoleListById
      })
  }
})

export const { selectGolRole, setLoader, setPageNo, setPageSize, setSearch } =
  department.actions

export default department.reducer
