import { DefaultRoute } from '../router/routes'
import moment from 'moment'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export const getNextDay = (dateString) => {
  var currentDate = new Date(dateString)
  var nextDay = new Date(currentDate)
  nextDay.setDate(currentDate.getDate() + 1)
  var formattedDate = nextDay.toISOString().split('T')[0]
  return formattedDate
}

export const getPreviousDay = (dateString) => {
  var currentDate = new Date(dateString)
  var previousDay = new Date(currentDate)
  previousDay.setDate(currentDate.getDate() - 1)
  var formattedDate = previousDay.toISOString().split('T')[0]
  return formattedDate
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: '2-digit', day: '2-digit', year: 'numeric' }
) => {
  if (!value || value?.length == 0) return ''
  return new Intl.DateTimeFormat('fr-CA', formatting).format(new Date(value))
}

// ** Returns short month of passed date. Eg. 2023-06-30 -> JUN 30
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date. Eg. 2023-06-30 -> JUN 2023
export const convertToMonthAndYear = (date) => {
  if (!date) {
    return ''
  }
  let formatting = { month: 'short', year: 'numeric' }
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(date))
}

export const convertDateTime = (originalDate) => {
  if (!originalDate) return '-'
  const formattedDateTime = moment(originalDate).format('DD-MM-YYYY HH:mm:ss')
  return formattedDateTime
}

// ** Returns short month of passed date. Eg. 2023-06-30 -> 30 JUN 2023
export const convertToDateMonthYear = (date) => {
  if (!date) {
    return ''
  }

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  const dateParts = date.split('-')
  const year = dateParts[0]
  const month = months[parseInt(dateParts[1]) - 1]
  const day = dateParts[2]

  return `${day} ${month} ${year}`
}

export const hasPermission = (temp) => {
  const data = JSON.parse(window.localStorage.getItem('userData'))?.permissions
  return data ? data.includes(temp) : false
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () =>
  JSON.parse(localStorage.getItem('userData'))?.accessToken
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const emailRegex = /^\S+@\S+\.\S+$/
export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,16})/
export const phoneRegex = /^([+]\d{2})?\d{10}$/
export const characterRegex = /^[0-9a-zA-Z_ ?=.*$-_!#~`]{0,100}$/
export const charNoSymbol = /^[0-9a-zA-Z_ _`]{0,100}$/
export const char50Regex = /^[0-9a-zA-Z_ ?=.*$-_!#~`]{0,50}$/
export const imageRegex = /([a-z\-_0-9\/\:\.%]*\.(jpg|jpeg|png|gif|photo|svg))/i
export const videoRegex =
  /^(?:(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be|vimeo\.com|dailymotion\.com|twitch\.tv|facebook\.com|twitter\.com|instagram\.com|soundcloud\.com|mixcloud\.com))\/(?:[\w\/]+)?(?:watch\?v=|embed\/|v\/)?([A-Za-z0-9_-]{11}|[A-Za-z0-9_-]{12})(?:\S+)?|(?:https?:\/\/)?(?:onedrive\.live\.com\/(?:download|embed))?\/(?:redir|photo)\?resid=([\w-]+)&(?:authkey=([\w-]+))?(?:\S+)?$/
export const numRegex = /^([1-9]|[1-9][0-9]|100)$/

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === 'admin') return DefaultRoute
  return '/login'
}
export const customStyles = {
  title: {
    style: {
      fontWeight: 'bold'
    }
  }
}
// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const titleCase = (str) => {
  if (str) {
    str = str.toLowerCase()
    str = str.split(' ')
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1).toUpperCase()
      return str.join(' ')
    }
  }
  return ''
}

// Returns false if end date is before start date
// It is very important that first param should be start and
// second should be end
export const isStartBeforeThanEnd = (start, end, format = true) => {
  if (!start || !end) {
    return false
  }

  const startDate = formatDate(start).toString() + (format ? ' 00:00:00' : '')
  const endDate = formatDate(end).toString() + (format ? ' 23:59:59' : '')

  return moment(startDate).isBefore(endDate)
}

export const getStatus = (
  enrollstatus,
  trackStatus,
  enrollStartDate,
  enrollCloseDate
) => {
  // console.log('enrollstatus', enrollstatus)
  // console.log('trackStatus', trackStatus)

  let mystatus = ''
  switch (enrollstatus) {
    case 'SUBMITTED':
      mystatus = 'Application Submitted'
      break
    case 'ACCEPT':
      mystatus = trackStatus === 'COMPLETE' ? 'Complete' : 'In Progress'

      break
    case 'REJECT':
      mystatus = 'Application Not Accepted'
      break

    case 'HOLD':
      mystatus = 'Application On-Hold'
      break
    default:
      if (!enrollCloseDate || !enrollStartDate) {
        return ''
      }

      const enroll_close_date = moment(
        new Date(enrollCloseDate + ' 23:59:59')
      ).format('M/D/YYYY')
      // console.log('enroll_close_date', enroll_close_date)
      const enroll_start_date = moment(
        new Date(enrollStartDate + ' 00:00:01')
      ).format('M/D/YYYY')
      // console.log('enroll_start_date', enroll_start_date)
      const todays_date = moment(new Date()).format('M/D/YYYY')
      // console.log('todays_date', todays_date)
      if (moment(enroll_start_date).isAfter(todays_date)) {
        mystatus = 'Coming Soon'
      } else if (
        moment(enroll_start_date).isSameOrBefore(todays_date) &&
        moment(todays_date).isSameOrBefore(enroll_close_date)
      ) {
        mystatus = 'Enroll Now'
      } else if (moment(enroll_close_date).isBefore(todays_date)) {
        mystatus = 'Enrollment Closed'
      }

      // mystatus = moment(enroll_close_date).isSameOrAfter(todayDate)
      //   ? 'Enroll Now'
      //   : 'Enrollment Closed'
      break
  }

  return mystatus
}

export const getLastDateOfMonth = (date) => {
  if (!date || date.length == 0) {
    return ''
  }
  const old_date = new Date(date)
  const year = old_date.getFullYear()
  const month = old_date.getMonth() + 1 // Months are zero-based, so we add 1
  const nextMonth = new Date(year, month, 0)
  const lastDate = nextMonth.getDate()

  // Format the date as "dd-mm-yyyy"
  const formattedDate = lastDate.toString().padStart(2, '0')
  const formattedMonth = month.toString().padStart(2, '0')
  const formattedYear = year.toString()

  return formattedYear + '-' + formattedMonth + '-' + formattedDate
}

export const convertFirstLetterToUppercase = (str) => {
  if (!str) {
    return ''
  }
  return str.substring(0, 1).toUpperCase() + str.substring(1)
}

export const encoded = (symbol) => {
  const encodedSymbol = encodeURIComponent(symbol)
  return encodedSymbol
}

export const defaultCard = {
  trackName: 'Godrej Capability Factors',
  imageUrl: '',
  description:
    'Leading Self workshop will help individuals develop a better appreciation of own strengths, areas of improvement and motivators.'
}

export const getOngoingTracks = (tracks, card) => {
  if (!tracks || tracks.length == 0) {
    return []
  }

  return [
    card,
    ...tracks?.filter(
      (word) => word?.enrollStatus === 'ACCEPT'
      // && word?.trackStatus !== 'COMPLETE'
    )
  ]
}

export const getUpcomingTracks = (tracks) => {
  if (!tracks || tracks.length == 0) {
    return []
  }

  const todays_date = moment(new Date()).format('M/D/YYYY')

  return tracks?.filter((word) => {
    return moment(
      moment(new Date(word?.enrollStartDate + ' 00:00:01')).format('M/D/YYYY')
    ).isAfter(todays_date)
  })
}
