import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../app.config'
import { encoded } from '../../../../utility/Utils'

export const fetchTrainerList = createAsyncThunk(
  'trainerMaster/fetchTrainerList',
  async (params, { dispatch }) => {
    try {
      const url = params?.pageNo
        ? `/trainer?pageNo=${params.pageNo}&pageSize=${
            params.pageSize
          }&search=${encoded(params.search)}`
        : `/trainer`
      const response = await axios.get(baseUrl() + url)
      return {
        trainerList: response?.data?.data?.map((a) => {
          a.id = a.trainerId
          return a
        }),
        totalPages: response?.data?.count?.total
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

export const getDropdownList = createAsyncThunk(
  'trainerMaster/getDropdownList',
  async (params) => {
    try {
      const response = await axios.get(baseUrl() + '/trainer', { params })
      return {
        dropdownList: response?.data?.data
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

export const fetchAllTrainer = createAsyncThunk(
  'trainerMaster/fetchAllTrainer',
  async (params, { dispatch }) => {
    try {
      const response = await axios.get(baseUrl() + '/trainer')
      return {
        allTrainer: response?.data?.data
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

export const addTrainer = createAsyncThunk(
  'trainerMaster/addTrainer',
  async ({ formData, params, fetchDropdownAfterSave }, { dispatch }) => {
    try {
      const response = await axios.post(baseUrl() + '/trainer', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      toast.success(response?.data?.message)
      if (fetchDropdownAfterSave) {
        dispatch(fetchAllTrainer())
      } else {
        dispatch(fetchTrainerList({ ...params }))
      }
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const editTrainer = createAsyncThunk(
  'trainerMaster/editTrainer',
  async ({ id, formData, params }, { dispatch }) => {
    try {
      await axios.put(baseUrl() + `/trainer/${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      toast.success('Faculty edited successfully!')
      dispatch(fetchTrainerList({ ...params }))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const deleteTrainer = createAsyncThunk(
  'trainerMaster/deleteTrainer',
  async ({ ids, pageNo, pageSize, search }, { dispatch }) => {
    dispatch(setLoader(true))
    try {
      const response = await axios.delete(baseUrl() + `/trainer`, {
        data: { ids }
      })
      toast.success(response?.data?.message)
      dispatch(fetchTrainerList({ pageNo, pageSize, search: search }))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const trainerMaster = createSlice({
  name: 'trainerMaster',
  initialState: {
    trainerList: [],
    dropdownList: [],
    allTrainer: [],
    loader: true,
    params: {
      pageNo: 1,
      pageSize: 10,
      search: ''
    },
    totalPages: 0,
    selected: null
  },
  reducers: {
    selectTrainer: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setPageNo: (state, action) => {
      state.params = { ...state.params, pageNo: action.payload }
    },
    setPageSize: (state, action) => {
      state.params = { ...state.params, pageSize: action.payload }
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTrainerList.fulfilled, (state, action) => {
      state.trainerList = action?.payload?.trainerList
      state.totalPages = action?.payload?.totalPages
      state.loader = false
    })
    builder.addCase(getDropdownList.fulfilled, (state, action) => {
      state.dropdownList = action?.payload?.dropdownList
    })
    builder.addCase(fetchAllTrainer.fulfilled, (state, action) => {
      state.allTrainer = action?.payload?.allTrainer
      state.loader = false
    })
  }
})

export const { selectTrainer, setLoader, setPageNo, setPageSize, setSearch } =
  trainerMaster.actions

export default trainerMaster.reducer
