import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../app.config'
import { getNextDay } from '../../../utility/Utils'
import { encoded } from '../../../utility/Utils'

export const getAttendanceData = createAsyncThunk(
  'attendance/getAttendanceData',
  async ({ params, filters }, { dispatch }) => {
    const response = await axios.post(
      baseUrl() +
        `/attendance?pageNo=${params.pageNo}&pageSize=${
          params.pageSize
        }&search=${encoded(params.search)}&export=${params.export}`,
      filters
    )
    return {
      params: params,
      totalPages: response?.data?.count?.total,
      AttendanceList: response?.data?.data
    }
  }
)

export const getAttendanceDetail = createAsyncThunk(
  'attendance/getAttendanceDetail',
  async ({ id }, { dispatch }) => {
    try {
      const response = await axios.get(baseUrl() + `/attendance/${id}`)
      return {
        detail: response?.data?.data
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

export const attendanceBulkUpload = createAsyncThunk(
  'attendance/attendanceBulkUpload',
  async ({ formData, params, filters }, { dispatch }) => {
    try {
      await axios.post(baseUrl() + '/attendance/upload', formData)
      toast.success('Attendance BulkUploaded successfully')
      dispatch(getAttendanceData({ params, filters }))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const fetchAllAttendance = createAsyncThunk(
  'attendance/fetchAllAttendance',
  async () => {
    try {
      const response = await axios.get(baseUrl() + '/attendance')
      return {
        allAttendance: response?.data?.data
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

export const addAttendance = createAsyncThunk(
  'attendance/addAttendance',
  async ({ formData, params }, { dispatch }) => {
    try {
      await axios.post(baseUrl() + '/attendance', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      toast.success('Attendance added successfully')
      dispatch(getAttendanceData({ ...params }))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const editAttendance = createAsyncThunk(
  'attendance/editAttendance',
  async ({ id, attendanceName, params }, { dispatch }) => {
    try {
      await axios.put(baseUrl() + `/sponsor/${id}`, attendanceName)
      toast.success('Attendance edited successfully')
      dispatch(getAttendanceData({ ...params }))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const editAttendanceStatus = createAsyncThunk(
  'attendance/editAttendanceStatus',
  async ({ payload, param }, { dispatch }) => {
    try {
      const response = await axios.put(
        baseUrl() + `/attendance/status`,
        payload
      )
      if (response?.data == '') {
        toast.error('Action freezed. Cannot update')
      } else {
        toast.success('Status Updated Successfully')
      }
      dispatch(getAttendanceData({ ...param }))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const editAttendanceLock = createAsyncThunk(
  'attendance/editAttendanceLock',
  async ({ payload, param }, { dispatch }) => {
    try {
      await axios.put(baseUrl() + `/attendance/lock`, payload)
      toast.success('Lock Attendance Updated Successfully')
      dispatch(getAttendanceData({ ...param }))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const editStarPerformance = createAsyncThunk(
  'attendance/editStarPerformance',
  async ({ payload, param }, { dispatch }) => {
    try {
      await axios.put(baseUrl() + `/attendance/star`, payload)
      toast.success('Star Performer Updated Successfully')
      dispatch(getAttendanceData({ ...param }))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const deleteAttendance = createAsyncThunk(
  'attendance/deleteAttendance',
  async ({ id, pageNo, pageSize, search }, { dispatch }) => {
    dispatch(setLoader(true))
    try {
      await axios.delete(baseUrl() + `/attendance/${id}`)
      toast.success('Attendance deleted successfully')
      dispatch(getAttendanceData({ pageNo, pageSize, search: search }))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const attendanceSlice = createSlice({
  name: 'attendance',
  initialState: {
    AttendanceList: [],
    getList: [],
    allAttendance: [],
    loader: false,
    params: {
      pageNo: 1,
      pageSize: 10,
      search: '',
      export: false
    },
    totalPages: 0,
    selected: null,
    filters: {
      edp: '',
      userId: '',
      email: '',
      function: '',
      grade: '',
      level: '',
      position: '',
      region: '',
      starPerformer: '',
      subtrack: '',
      subtrackCompleteDate: '',
      track: '',
      zone: '',
      status: ''
    }
  },
  reducers: {
    selectAttendance: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        let formattedDate = ''
        if (action?.payload?.subtrackCompleteDate !== '') {
          const dateObj = new Date(action?.payload?.subtrackCompleteDate)
          let inputDate = dateObj.toISOString().slice(0, 10)
          formattedDate = getNextDay(inputDate)
        }
        state.selected = {
          ...action.payload,
          subtrackCompleteDate: formattedDate
        }
      }
    },
    selectedFilters: (state, action) => {
      if (action.payload === null) {
        state.filters = {
          edp: '',
          email: '',
          userId: '',
          function: '',
          grade: '',
          level: '',
          position: '',
          region: '',
          starPerformer: '',
          subtrack: '',
          subtrackCompleteDate: '',
          track: '',
          zone: '',
          status: ''
        }
      } else {
        state.filters = action.payload
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setPageNo: (state, action) => {
      state.params = { ...state.params, pageNo: action.payload }
    },
    setPageSize: (state, action) => {
      state.params = { ...state.params, pageSize: action.payload }
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAttendanceData.fulfilled, (state, action) => {
        state.AttendanceList = action?.payload?.AttendanceList
        state.totalPages = action?.payload?.totalPages
        state.loader = false
      })
      .addCase(fetchAllAttendance.fulfilled, (state, action) => {
        state.allAttendance = action?.payload?.allAttendance
        state.loader = false
      })
  }
})

export const {
  selectAttendance,
  selectedFilters,
  setLoader,
  setPageNo,
  setPageSize,
  setSearch
} = attendanceSlice.actions

export default attendanceSlice.reducer
