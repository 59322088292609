// ** React Imports
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Col,
  Form,
  Label,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  Row,
  FormFeedback
} from 'reactstrap'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import Select from 'react-select'
import { selectThemeColors } from '@utils'
import { useForm, Controller } from 'react-hook-form'
import {
  fetchCareerAspirationList,
  selectCareerAspiration,
  selectedFilters,
  setLoader,
  setPageNo
} from './store'
import { fetchFunctionList } from '../masters/functions/store'
import { fetchDepartmentMasterDropdown } from '../masters/department/store'

const defaultValues = {
  department: [],
  function: [],
  blankRecords: ''
}

const CareerFilterForm = ({
  params,
  filter,
  handleFilterClosed,
  setFilter,
  setClearFilter,
  setSelected
}) => {
  //   // ** States
  const dispatch = useDispatch()
  const { selected } = useSelector((state) => state?.careerAspirationAdmin)
  const { functionList } = useSelector((state) => state?.functions)
  const { departmentMasterDropdown } = useSelector((state) => state.department)
  const [error, setError] = useState(false)
  const { reset, control, handleSubmit } = useForm({
    defaultValues: selected === null ? defaultValues : selected
  })

  const departmentOptions = departmentMasterDropdown?.map((department) => {
    return {
      label: department.gplDepartmentName,
      value: department.gplDepartmentId
    }
  })

  fetchDepartmentMasterDropdown
  const functionOptions = functionList?.map((func) => {
    return {
      label: func.name,
      value: func.id
    }
  })

  const blankOptions = [
    { label: 'Unfilled', value: 'true' },
    { label: 'Filled', value: 'false' }
  ]

  const handleFilter = (formData) => {
    dispatch(setPageNo(1))
    if (
      formData?.department?.length === 0 &&
      formData?.function?.length === 0 &&
      formData?.blankRecords?.length === 0
    ) {
      setError(true)
    } else {
      dispatch(selectCareerAspiration(formData))
      const payload = {
        department: formData?.department
          ? formData?.department?.map((temp) => temp.value).join(',') ?? ''
          : '',
        function: formData?.function
          ? formData?.function?.map((temp) => temp.value).join(',') ?? ''
          : '',
        blankRecords: formData?.blankRecords.value
      }
      dispatch(setLoader(true))
      dispatch(
        fetchCareerAspirationList({
          params: {
            pageNo: 1,
            pageSize: params.pageSize,
            search: '',
            export: false,
            ...payload
          }
        })
      )
      dispatch(selectedFilters(payload))
      setFilter(false)
      setClearFilter(true)
    }
  }

  const handleReset = () => {
    setClearFilter(false)
    dispatch(setLoader(true))
    dispatch(
      fetchCareerAspirationList({
        params: {
          pageNo: 1,
          pageSize: 10,
          search: '',
          export: false,
          department: '',
          function: '',
          blankRecords: ''
        }
      })
    )
    dispatch(setSelected(null))
    setFilter(!filter)
    reset()
  }

  useEffect(() => {
    dispatch(fetchFunctionList())
    dispatch(fetchDepartmentMasterDropdown())
  }, [])
  return (
    <>
      <Modal
        isOpen={filter}
        onClosed={handleFilterClosed}
        toggle={() => setFilter(!filter)}
        className="modal-dialog-centered  modal-lg"
      >
        <ModalHeader
          className="bg-transparent "
          toggle={() => setFilter(!filter)}
        ></ModalHeader>
        <ModalBody style={{ padding: '0.8rem 2.5rem' }}>
          <div className="text-center mb-0">
            <h1 className="mb-1 ">Filters</h1>
          </div>

          <Row tag={Form} onSubmit={handleSubmit((data) => handleFilter(data))}>
            <Col
              xs={12}
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                columnGap: '32px'
              }}
            >
              <div className="mb-1">
                <Label className="form-label" for="status">
                  Filter By Department
                </Label>
                <Controller
                  id="department"
                  name="department"
                  control={control}
                  render={({ field }) => (
                    <Select
                      classNamePrefix="select"
                      isMulti
                      options={departmentOptions}
                      theme={selectThemeColors}
                      {...field}
                    />
                  )}
                />
              </div>

              <div className="mb-1">
                <Label className="form-label" for="function">
                  Filter By Function
                </Label>
                <Controller
                  id="function"
                  name="function"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isClearable={false}
                      classNamePrefix="select"
                      options={functionOptions}
                      isMulti
                      theme={selectThemeColors}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="mb-1">
                <Label className="form-label" for="blankRecords">
                  Filter By Blank Forms
                </Label>
                <Controller
                  id="blankRecords"
                  name="blankRecords"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isClearable={false}
                      classNamePrefix="select"
                      options={blankOptions}
                      theme={selectThemeColors}
                      {...field}
                    />
                  )}
                />
                {error && (
                  <FormFeedback>Please Select Atleast One Filter</FormFeedback>
                )}
              </div>
            </Col>
            <Col xs={12} className="text-center mt-2 mb-1">
              <Button className="me-1" color="primary" type="submit">
                Filter
              </Button>
              <Button outline type="reset" onClick={handleReset}>
                Clear
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}

export default CareerFilterForm
