import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../../../app.config'

export const fetchCareerAspiration = createAsyncThunk(
  'careerAspiration/fetchCareerAspiration',
  async (id, { dispatch }) => {
    try {
      const response = await axios.get(
        baseUrl() + `/career-aspiration?userId=${id}`
      )

      dispatch(setLoader(false))
      return {
        careerAspiration: response?.data?.data
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

export const fetchUserDetail = createAsyncThunk(
  'careerAspiration/fetchUserDetail',
  async (id, { dispatch }) => {
    try {
      const response = await axios.get(
        baseUrl() + `/permissions/user-sf-detail?userId=${id}`
      )
      return {
        userDetail: response?.data?.data
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

export const downloaFile = async (newFile) => {
  try {
    const response = await axios
      .get(baseUrl() + `/downloadFile/${newFile}`)
      .then((response) => {
        let url = URL.createObjectURL(response.data)
        let a = document.createElement('a')
        a.href = url
        a.download = 'file.pdf'
        a.click()
        //window.location.href = response.url;
      })
    return {
      downloadedFile: response?.data?.data
    }
  } catch (e) {
    toast.error(e?.response?.data?.message)
  }
}

export const editCareerAspiration = createAsyncThunk(
  'careerAspiration/editCareerAspiration',
  async ({ id, careerAspiration, params }, { dispatch }) => {
    try {
      await axios.patch(baseUrl() + `/user/${id}/career-aspiration`, {
        careerAspiration
      })
      const newObject = JSON.parse(localStorage.getItem('userData'))
      newObject.careerAspiration = careerAspiration
      localStorage.setItem('userData', JSON.stringify(newObject))
      // toast.success('Career Aspiration Added Successfully')
      toast.success('Career Aspiration Added Successfully')
      dispatch(fetchCareerAspiration({ ...params }))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const addCareerAspiration = createAsyncThunk(
  'careerAspiration/addCareerAspiration',
  async ({ formData, id }, { dispatch }) => {
    try {
      await axios.post(baseUrl() + `/career-aspiration`, formData)
      toast.success('Career Aspiration added successfully')
      dispatch(fetchCareerAspiration(id))
      dispatch(setDisabled(true))
      dispatch(setLoader(false))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const careerAspiration = createSlice({
  name: 'careerAspiration',
  initialState: {
    userDetail: '',
    careerAspiration: '',
    loader: false,
    downloadedFile: '',
    downloadedFileStatus: false,
    openModal: false,
    disabled: true
  },
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setDisabled: (state, action) => {
      state.disabled = action.payload
    },
    setDownloadedFileStatus: (state, action) => {
      state.downloadedFileStatus = action.payload
    },
    setOpenModal: (state, action) => {
      state.openModal = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCareerAspiration.fulfilled, (state, action) => {
        state.careerAspiration = action?.payload?.careerAspiration
        state.totalPages = action?.payload?.totalPages
        state.loader = false
        state.downloadedFileStatus = false
      })
      .addCase(fetchUserDetail.fulfilled, (state, action) => {
        state.userDetail = action?.payload?.userDetail
      })
  }
})

export const { setLoader, setDownloadedFileStatus, setOpenModal, setDisabled } =
  careerAspiration.actions

export default careerAspiration.reducer
