import { React, useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { ChevronDown, Edit, Trash } from 'react-feather'
import { Button } from 'reactstrap'
import Form from './Form'
import CustomHeader from '../../../utility/commonComponents/CustomHeader'
import DeleteModal from '../../../components/modals/deleteModal'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteGplRole,
  fetchGplRole,
  selectGolRole,
  setLoader,
  setPageNo,
  setPageSize,
  setSearch
} from './store'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Spinner from '@components/spinner/Loading-spinner'
import CustomPagination from '../../../utility/commonComponents/Pagination'
import { hasPermission } from '../../../utility/Utils'
import DeleteMultiselectButton from '../../../utility/commonComponents/DeleteMultiselectButton'

function Table() {
  let { gplRoleList, loader, totalPages, selected, params } = useSelector(
    (state) => state?.gplRole
  )

  const { pageNo, pageSize, search } = params
  const [show, setShow] = useState(false)
  const [showDeleteModal, setDeleteModal] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [toggleCleared, setToggleCleared] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setLoader(true))
      dispatch(
        fetchGplRole({
          pageNo: 1,
          pageSize,
          search: search
        })
      )
      dispatch(setPageNo(1))
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [search])

  useEffect(() => {
    return () => {
      dispatch(setSearch(''))
      dispatch(setPageNo(1))
      dispatch(setPageSize(10))
      dispatch(setLoader(true))
    }
  }, [])

  const handleEditClick = async (data) => {
    setShow(true)
    dispatch(selectGolRole(data))
  }

  const closeDeleteModal = () => {
    dispatch(selectGolRole(null))
    setToggleCleared(!toggleCleared)
    setSelectedRows([])
    setDeleteModal(false)
  }

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows)
  }, [])

  const handleMultiDelete = () => {
    setDeleteModal(true)
  }
  const deletePropsConfig = {
    deletefunc: deleteGplRole,
    payload: {
      ids: selectedRows.map((item) => item?.gplRoleId),
      pageNo,
      pageSize,
      search
    },
    closeDeleteModal,
    showDeleteModal
  }

  const dataToRender = () => {
    return gplRoleList
  }

  const handlePageNoChange = (page) => {
    dispatch(setLoader(true))
    dispatch(
      fetchGplRole({
        pageNo: page?.selected + 1,
        pageSize,
        search: search
      })
    )
    setToggleCleared(!toggleCleared)
    setSelectedRows([])
    dispatch(setPageNo(page?.selected + 1))
  }

  const handlePageSizeChange = (e) => {
    const value = parseInt(e.currentTarget.value)
    dispatch(setLoader(true))
    dispatch(
      fetchGplRole({
        pageSize: value,
        search: search,
        pageNo: 1
      })
    )
    dispatch(setPageSize(value))
    dispatch(setPageNo(1))
  }

  const renderPaginationComponent = () => {
    return (
      <CustomPagination
        pageProps={{ pageNo, pageSize, totalPages }}
        handlePageNoChange={handlePageNoChange}
        handlePageSizeChange={handlePageSizeChange}
      />
    )
  }

  const columns = [
    {
      name: 'Role Name',
      selector: 'gplRole',
      sortable: false,
      selector: (row) => row?.gplRoleName,
      //cell: (row) => <span>{row?.name}</span>
      // width: '20%'
      cell: (row) =>
        row?.gplRoleName?.length > 10 ? (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="toolTip">
                <div className="toolTip ">{row?.gplRoleName}</div>
              </Tooltip>
            }
          >
            <span className=" table_row ">{row?.gplRoleName}</span>
          </OverlayTrigger>
        ) : (
          <span className=" ">{row?.gplRoleName}</span>
        )
    },
    {
      name: 'Department Name',
      selector: 'name',
      sortable: false,
      selector: (row) => row?.gplDepartmentName,
      //cell: (row) => <span>{row?.gplDepartmentName}</span>
      cell: (row) =>
        row?.gplDepartmentName?.length > 10 ? (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="toolTip">
                <div className="toolTip ">{row?.gplDepartmentName}</div>
              </Tooltip>
            }
          >
            <span className=" table_row ">{row?.gplDepartmentName}</span>
          </OverlayTrigger>
        ) : (
          <span className=" ">{row?.gplDepartmentName}</span>
        )
      // width: '20%'
    },
    {
      name: 'Function Name',
      selector: 'gplRole',
      sortable: false,
      selector: (row) => row?.functionName,
      // cell: (row) => (
      //   <span>{row?.functionName}</span>
      // )
      cell: (row) =>
        row?.functionName?.length > 10 ? (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="toolTip">
                <div className="toolTip ">{row?.functionName}</div>
              </Tooltip>
            }
          >
            <span className=" table_row ">{row?.functionName}</span>
          </OverlayTrigger>
        ) : (
          <span className=" ">{row?.functionName}</span>
        )

      // width: '20%'
    },
    {
      name: 'Actions',
      // width: '20%',
      selector: 'action',
      cell: (row) => (
        <>
          {hasPermission('Gpl_Role_Update') && (
            <Button
              size="sm"
              color="transparent"
              className="btn btn-icon"
              onClick={() => handleEditClick(row)}
            >
              <Edit className="font-medium-2" />
            </Button>
          )}
          {hasPermission('Gpl_Role_Delete') && (
            <Button
              size="sm"
              color="transparent"
              className="btn btn-icon"
              disabled={selectedRows?.length > 0}
              onClick={() => {
                setSelectedRows([row])
                dispatch(selectGolRole(row))
                setDeleteModal(true)
              }}
            >
              <Trash className="font-medium-2" />
            </Button>
          )}
        </>
      ),
      minWidth: '200px',
      sortable: false,
      center: true
    }
  ]

  const addClick = () => {
    setShow(true)
  }

  return (
    <>
      <DeleteModal {...deletePropsConfig} />
      <div className="react-dataTable">
        <DataTable
          title={
            <CustomHeader
              search={search}
              showSearch={true}
              handleSearch={(temp) => dispatch(setSearch(temp))}
              addClick={addClick}
              title="Role List"
              addName="Add GPL Role "
              permissions={hasPermission('Gpl_Role_Add')}
            />
          }
          pagination
          responsive
          highlightOnHover
          paginationServer
          progressComponent={<Spinner />}
          progressPending={loader}
          columns={columns}
          data={dataToRender()}
          sortIcon={<ChevronDown />}
          selectableRows
          contextActions={
            <DeleteMultiselectButton
              selectedRows={selectedRows}
              toggleCleared={toggleCleared}
              handleMultiDelete={handleMultiDelete}
            />
          }
          onSelectedRowsChange={handleRowSelected}
          clearSelectedRows={toggleCleared}
          className="react-dataTable"
          paginationComponent={renderPaginationComponent}
        />
      </div>

      {show && <Form show={show} setShow={setShow} />}
    </>
  )
}

export default Table
