import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { addHomePdf } from './store'
import { Trash2 } from 'react-feather'

const ReplaceModal = ({ show, setShow, keyId, fileUrl }) => {
  const { loader, selected, params } = useSelector((state) => state?.home)

  const dispatch = useDispatch()
  const [file, setFile] = useState('')

  const [fileName, setFileName] = useState('')
  // const [show, setShow] = useState(false)

  const defaultValues = {
    file: '',
    fileName: ''
  }

  const {
    reset,
    control,
    setError,
    setValue,
    register,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues })

  const onSubmit = async (data) => {
    let response = ''
    const formData = new FormData()
    formData.append('file', file),
      formData.append('key', keyId),
      (response = await dispatch(
        addHomePdf({
          formData
        })
      ))

    if (response.payload) {
      setShow(false)
    }
  }

  const handleRemovedFileUrl = () => {
    setFile('')
    setValue('file', '')
  }

  const handleModalClosed = () => {
    setFile('')
    setShow(false)
    reset()
  }

  const handleFile = (e) => {
    setFileName(e.target?.files[0].name)
    setFile(e.target?.files[0])
    setValue('file', e.target?.files[0])
  }

  useEffect(() => {
    setValue('file', fileUrl)
    setFile(fileUrl)
    setFileName(fileUrl)
  }, [fileUrl])
  return (
    <>
      <Modal
        isOpen={true}
        onClosed={handleModalClosed}
        toggle={handleModalClosed}
        className="modal-dialog-centered "
      >
        <ModalHeader
          className="bg-transparent "
          toggle={handleModalClosed}
        ></ModalHeader>
        <ModalBody>
          <div className="text-center mb-0">
            <h1 className="mb-0">Replace File</h1>
          </div>

          <Row tag={Form} className="p-2" onSubmit={handleSubmit(onSubmit)}>
            {!file ? (
              <Col xl={12} className="mb-1" display="flex">
                <Label className="form-label" for="url">
                  File Upload (.pdf) <span className="text-danger">*</span>
                </Label>
                <Controller
                  id="file"
                  name="file"
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="file"
                      name="file"
                      accept="application/pdf"
                      {...register('file', {
                        required: 'Please upload a file',
                        validate: (value) =>
                          value.type === 'application/pdf' || (
                            <p>Please Upload Pdf File</p>
                          )
                      })}
                      invalid={errors.file && true}
                      onChange={handleFile}
                    />
                  )}
                />
                {errors && errors.file && (
                  <FormFeedback>{errors.file.message}</FormFeedback>
                )}
              </Col>
            ) : (
              <Col xs={12} display="flex">
                <Label className="form-label d-flex" for="fileUrl">
                  File Name
                </Label>
                <div className="d-flex ">
                  <Controller
                    name="fileName"
                    control={control}
                    render={({ field }) => (
                      <Input name="fileName" value={fileName} />
                    )}
                  />
                  <Button
                    color="danger"
                    outline
                    size="sm"
                    className="btn-icon ms-1"
                    onClick={() => handleRemovedFileUrl()}
                  >
                    <Trash2 size={14} />
                  </Button>
                </div>
              </Col>
            )}

            <Col xs={12} className="text-center mt-2 mb-1">
              <Button className="me-1" color="primary" type="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ReplaceModal
