import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../app.config'
import { encoded } from '../../../../utility/Utils'

export const fetchCityList = createAsyncThunk(
  'city/fetchCityList',
  async (params, { dispatch }) => {
    try {
      const response = await axios.get(baseUrl() + '/city', { params })
      return {
        cityList: response?.data?.data?.data,
        totalPages: response?.data?.data?.count?.total
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

export const fetchCityDropdown = createAsyncThunk(
  'city/fetchCityDropdown',
  async (params, { dispatch }) => {
    try {
      const response = await axios.get(baseUrl() + '/city')
      return {
        cityDropdown: response?.data?.data
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

export const addCity = createAsyncThunk(
  'city/addCity',
  async ({ city, description, params }, { dispatch }) => {
    try {
      await axios.post(baseUrl() + '/city', {
        city: city
      })
      toast.success('City added successfully')
      dispatch(fetchCityList({ ...params }))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const editCity = createAsyncThunk(
  'city/editCity',
  async ({ id, city, description, params }, { dispatch }) => {
    try {
      await axios.put(baseUrl() + `/city/${id}`, {
        city,
        description
      })
      toast.success('City edited successfully')
      dispatch(fetchCityList({ ...params }))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const deleteCity = createAsyncThunk(
  'city/deleteCity',
  async ({ ids, pageNo, pageSize, search }, { dispatch }) => {
    dispatch(setLoader(true))
    try {
      await axios.delete(baseUrl() + `/city`, {
        data: { ids }
      })
      if (ids.length > 1) {
        toast.success('Cities deleted successfully')
      } else {
        toast.success('City deleted successfully')
      }

      dispatch(fetchCityList({ pageNo: 1, pageSize, search: search }))
      dispatch(setPageNo(1))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const city = createSlice({
  name: 'city',
  initialState: {
    cityList: [],
    cityDropdown: [],
    loader: true,
    params: {
      pageNo: 1,
      pageSize: 10,
      search: ''
    },
    totalPages: 0,
    selected: null
  },
  reducers: {
    selectCity: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setPageNo: (state, action) => {
      state.params = { ...state.params, pageNo: action.payload }
    },
    setPageSize: (state, action) => {
      state.params = { ...state.params, pageSize: action.payload }
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCityList.fulfilled, (state, action) => {
      state.cityList = action?.payload?.cityList
      state.totalPages = action?.payload?.totalPages
      state.loader = false
    }),
      builder.addCase(fetchCityDropdown.fulfilled, (state, action) => {
        state.cityDropdown = action?.payload?.cityDropdown
      })
  }
})

export const { selectCity, setLoader, setPageNo, setPageSize, setSearch } =
  city.actions

export default city.reducer
