import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../app.config'
import { encoded } from '../../../../utility/Utils'

export const fetchFunctionList = createAsyncThunk(
  'functions/fetchFunctionList',
  async (params, { dispatch }) => {
    try {
      const url = params?.pageNo
        ? `/gpl-function?pageNo=${params.pageNo}&pageSize=${
            params.pageSize
          }&search=${encoded(params.search)}`
        : `/gpl-function`
      const response = await axios.get(baseUrl() + url)
      return {
        functionList: response?.data?.data,
        totalPages: response?.data?.count?.total
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

export const fetchFunctionsDropdown = createAsyncThunk(
  'functions/fetchFunctionsDropdown',
  async (params, { dispatch }) => {
    try {
      const response = await axios.get(baseUrl() + '/gpl-function')
      return {
        functionDropdown: response?.data?.data
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

export const addFunction = createAsyncThunk(
  'functions/addFunction',
  async ({ name, description, params }, { dispatch }) => {
    try {
      await axios.post(baseUrl() + '/gpl-function', {
        name,
        description
      })
      toast.success('Function added successfully')
      dispatch(fetchFunctionList({ ...params }))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const editFunction = createAsyncThunk(
  'functions/editFunction',
  async ({ id, name, description, params }, { dispatch }) => {
    try {
      await axios.put(baseUrl() + `/gpl-function/${id}`, {
        name,
        description
      })
      toast.success('Function edited successfully')
      dispatch(fetchFunctionList({ ...params }))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const deleteFunction = createAsyncThunk(
  'functions/deleteFunction',
  async ({ ids, pageNo, pageSize, search }, { dispatch }) => {
    dispatch(setLoader(true))
    try {
      await axios.delete(baseUrl() + `/gpl-function`, {
        data: { ids }
      })
      toast.success('Function deleted successfully')
      dispatch(fetchFunctionList({ pageNo: 1, pageSize, search: search }))
      dispatch(setPageNo(1))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const functions = createSlice({
  name: 'functions',
  initialState: {
    functionList: [],
    functionDropdown: [],
    loader: true,
    params: {
      pageNo: 1,
      pageSize: 10,
      search: ''
    },
    totalPages: 0,
    selected: null
  },
  reducers: {
    selectFunction: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setPageNo: (state, action) => {
      state.params = { ...state.params, pageNo: action.payload }
    },
    setPageSize: (state, action) => {
      state.params = { ...state.params, pageSize: action.payload }
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFunctionList.fulfilled, (state, action) => {
      state.functionList = action?.payload?.functionList
      state.totalPages = action?.payload?.totalPages
      state.loader = false
    }),
      builder.addCase(fetchFunctionsDropdown.fulfilled, (state, action) => {
        state.functionDropdown = action?.payload?.functionDropdown
      })
  }
})

export const { selectFunction, setLoader, setPageNo, setPageSize, setSearch } =
  functions.actions

export default functions.reducer
