import React from 'react'
import { useMemo } from 'react'
import { Button } from 'reactstrap'

const DeleteMultiselectButton = (props) => {
  const contextActions = useMemo(() => {
    return (
      <Button
        key="delete"
        style={{ backgroundColor: 'red' }}
        icon
        onClick={props.handleMultiDelete}
      >
        Delete
      </Button>
    )
  }, [props.toggleCleared])

  return contextActions
}

export default DeleteMultiselectButton
