import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap'
import { addHomePdf, setLoader } from './store'
import { Trash2 } from 'react-feather'

const defaultValues = {
  architectureImgWeb: '',
  architectureImgMob: ''
}

const ArchitectureImgForm = ({ isShow, setIsShow, keyId }) => {
  const { homeList, loader, selected, params } = useSelector(
    (state) => state?.home
  )

  const {
    reset,
    control,
    setError,
    setValue,
    getValues,
    trigger,
    register,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues })

  const dispatch = useDispatch()
  const [file, setFile] = useState('')
  const [imgWeb, setImgWeb] = useState('')
  const [imgMob, setImgMob] = useState('')
  const [updateFlag1, setUpdateFlag1] = useState(false)
  const [updateFlag2, setUpdateFlag2] = useState(false)

  const handleModalClosed = () => {
    setFile('')
    setIsShow(false)
    reset()
    setUpdateFlag1(false)
    setUpdateFlag2(false)
  }

  const handlearchitectureImgWeb = async (data) => {
    const result = await trigger('architectureImgWeb')
    if (result) {
      dispatch(setLoader(true))
      let response = ''
      const formData = new FormData()
      formData.append('file', file),
        formData.append('key', 'ARCHITECTURE_IMAGE_WEB'),
        (response = await dispatch(
          addHomePdf({
            formData
          })
        ))
      setIsShow(false)
      dispatch(setLoader(false))
    }
  }

  const handlearchitectureImgMob = async (data) => {
    const result = await trigger('architectureImgMob')
    if (result) {
      dispatch(setLoader(true))
      let response = ''
      const formData = new FormData()
      formData.append('file', file),
        formData.append('key', 'ARCHITECTURE_IMAGE_MOB'),
        (response = await dispatch(
          addHomePdf({
            formData
          })
        ))
      setIsShow(false)
      dispatch(setLoader(false))
    }
  }

  useEffect(() => {
    let webImg = ''
    let mobileImg = ''
    homeList.map((e) => {
      if (e.key === 'ARCHITECTURE_IMAGE_WEB') {
        webImg = e.originalName
      } else if (e.key === 'ARCHITECTURE_IMAGE_MOB') {
        mobileImg = e.originalName
      }
    })
    setValue('architectureImgWeb', webImg)
    setValue('architectureImgMob', mobileImg)
  }, [homeList])

  const handleRemovedImgWebFiles = () => {
    setImgWeb('')
    setValue('architectureImgWeb', '')
    setUpdateFlag1(true)
  }

  const handleRemovedImgMobFiles = () => {
    setImgMob('')
    setValue('architectureImgMob', '')
    setUpdateFlag2(true)
  }

  useEffect(() => {
    setImgWeb(getValues('architectureImgWeb'))
    setImgMob(getValues('architectureImgMob'))
  }, [])

  return (
    <>
      <Modal
        isOpen={isShow}
        onClosed={handleModalClosed}
        toggle={handleModalClosed}
        className="modal-dialog-centered"
      >
        <ModalHeader
          className="bg-transparent "
          toggle={handleModalClosed}
        ></ModalHeader>

        <ModalBody>
          <div className="text-center mb-0">
            <h1 className="mb-1"> Update Architecture Image</h1>
          </div>

          <Row tag={Form} className="p-0 mb-4 archtAlchemy">
            <Row className="architectureImg">
              {!imgWeb ? (
                <Col xs={8} display="flex">
                  <Label className="form-label" for="url">
                    Architecture Image Web (.png, .jpeg, .jpg)
                    <span className="text-danger">*</span>
                  </Label>
                  <Controller
                    id="architectureImgWeb"
                    name="architectureImgWeb"
                    control={control}
                    render={({ field }) => (
                      <Input
                        type="file"
                        accept="image/png,image/jpeg"
                        name="architectureImgWeb"
                        files={file}
                        {...register('architectureImgWeb', {
                          required: 'Please upload file',
                          validate: (value) =>
                            value.type === 'image/jpeg' ||
                            value.type === 'image/png' || (
                              <p style={{ marginBottom: '0px !important' }}>
                                Please select valid format
                              </p>
                            )
                        })}
                        invalid={errors.architectureImgWeb && true}
                        onChange={(e) => {
                          setFile(e.target.files[0])
                          setValue('architectureImgWeb', e.target.files[0])
                          setUpdateFlag1(true)
                        }}
                      />
                    )}
                  />
                </Col>
              ) : (
                <Col xs={8} display="flex">
                  <Label className="form-label d-flex" for="fileUrl">
                    Architecture Image Web (.png, .jpeg, .jpg)
                  </Label>
                  <div className="d-flex ">
                    <Controller
                      name="architectureImgWeb"
                      control={control}
                      render={({ field }) => (
                        <Input name="architectureImgWeb" {...field} />
                      )}
                    />
                    <Button
                      color="danger"
                      outline
                      size="sm"
                      className="btn-icon ms-1"
                      onClick={() => handleRemovedImgWebFiles()}
                    >
                      <Trash2 size={14} />
                    </Button>
                  </div>
                </Col>
              )}

              <Col xs={4} mb-1 style={errors.architectureImgWeb}>
                <Button
                  className="me-1"
                  color="primary"
                  onClick={handlearchitectureImgWeb}
                  disabled={loader || !updateFlag1}
                >
                  Submit
                </Button>
              </Col>

              {errors && errors.architectureImgWeb && (
                <FormFeedback style={{ marginBottom: '0px !important' }}>
                  {errors.architectureImgWeb.message}
                </FormFeedback>
              )}
            </Row>

            <Row className="architectureImg">
              {!imgMob ? (
                <Col xs={8} display="flex">
                  <Label className="form-label" for="url">
                    Architecture Image Mobile (.png, .jpeg, .jpg)
                    <span className="text-danger">*</span>
                  </Label>
                  <Controller
                    id="architectureImgMob"
                    name="architectureImgMob"
                    control={control}
                    render={({ field }) => (
                      <Input
                        type="file"
                        accept="image/png,image/jpeg"
                        name="architectureImgMob"
                        files={file}
                        {...register('architectureImgMob', {
                          required: 'Please upload file',
                          validate: (value) =>
                            value.type === 'image/jpeg' ||
                            value.type === 'image/png' || (
                              <p>Please select valid format</p>
                            )
                        })}
                        invalid={errors.architectureImgMob && true}
                        onChange={(e) => {
                          setFile(e.target.files[0])
                          setValue('architectureImgMob', e.target.files[0])
                          setUpdateFlag2(true)
                        }}
                      />
                    )}
                  />
                </Col>
              ) : (
                <Col xs={8} display="flex">
                  <Label className="form-label d-flex" for="fileUrl">
                    Architecture Image Mobile (.png, .jpeg, .jpg)
                  </Label>
                  <div className="d-flex ">
                    <Controller
                      name="architectureImgMob"
                      control={control}
                      render={({ field }) => (
                        <Input name="architectureImgMob" {...field} />
                      )}
                    />
                    <Button
                      color="danger"
                      outline
                      size="sm"
                      className="btn-icon ms-1"
                      onClick={() => handleRemovedImgMobFiles()}
                    >
                      <Trash2 size={14} />
                    </Button>
                  </div>
                </Col>
              )}

              <Col xs={4} mb-1 style={errors.architectureImgMob}>
                <Button
                  className="me-1"
                  color="primary"
                  onClick={handlearchitectureImgMob}
                  disabled={loader || !updateFlag2}
                >
                  Submit
                </Button>
              </Col>

              {errors && errors.architectureImgMob && (
                <FormFeedback>{errors.architectureImgMob.message}</FormFeedback>
              )}
            </Row>
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ArchitectureImgForm
